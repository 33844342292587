/* eslint-disable no-undef */
import { useEffect } from 'react';

const useClickOutside = (elementRef, buttonRef, onClick) => {
    useEffect(() => {
        const clickListener = (event) => {
            if (
                !elementRef.current ||
                elementRef.current.contains(event.target) ||
                buttonRef.current.contains(event.target)
            ) {
                return;
            }

            onClick(event);
        };

        document.addEventListener('mousedown', clickListener);
        document.addEventListener('touchstart', clickListener);

        return () => {
            document.removeEventListener('mousedown', clickListener);
            document.removeEventListener('touchstart', clickListener);
        };
    }, [elementRef, buttonRef, onClick]);
};

export default useClickOutside;
