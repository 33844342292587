import React from 'react';

const SelectUncontrolled = ({ name, label, value, options, onChange }) => {
    return (
        <div>
            <label
                htmlFor={name}
                className="block uppercase text-gray-700 text-xs font-bold mt-4 mb-1"
            >
                <span className="text-gray-600">{label}</span>
            </label>
            <select
                id={name}
                className="
                rounded mt-1 block w-full
                border-1
                border-gray-400
                focus:ring-1
                focus:ring-primaryHover
                focus:border-primaryHover
                "
                name={name}
                value={value}
                onChange={(e) => {
                    onChange(e.target.value);
                }}
            >
                <option disabled value="">
                    Selecione uma opção
                </option>
                {options.map((option) => {
                    return (
                        <option key={option.id} value={option.value}>
                            {option.label}
                        </option>
                    );
                })}
            </select>
        </div>
    );
};

export default SelectUncontrolled;
