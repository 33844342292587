import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

history.listen(() => {
    // eslint-disable-next-line no-undef
    window.scrollTo(0, 0);
});

export default history;
