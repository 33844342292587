import React, { useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { ReactComponent as ActiveSvg } from '@/assets/svgs/active.svg';
import { ReactComponent as ArrowLeftSvg } from '@/assets/svgs/arrow-left.svg';
import { ReactComponent as BankSvg } from '@/assets/svgs/bank.svg';
import { ReactComponent as DesactiveSvg } from '@/assets/svgs/desactive.svg';
import { ReactComponent as LoadingSvg } from '@/assets/svgs/loading.svg';
import ActivitiesModal from '@/components/Modals/ActivitiesModal';
import ConfirmModal from '@/components/Modals/ConfirmModal';
import TablePartners from '@/components/TablePartners';
/* import TesteTable from '@/components/TablePartners/ListRow'; */
import UINumber from '@/components/UINumber';
import { useAuth } from '@/contexts/AuthContext';
import { usePartner } from '@/contexts/PartnerContext';
import history from '@/services/history';

const ViewPartner = () => {
    const { user } = useAuth();
    const { id } = useParams();
    const {
        loading,
        partner,
        modalActivetie,
        setModalActivitie,
        modalDisable,
        modalActive,
        setModalDisable,
        setModalActive,
        handleGetPartner,
        handleDisablePartner,
        handleActivePartner,
    } = usePartner();

    useEffect(() => {
        handleGetPartner(id);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (loading) {
        return (
            <div className="w-full h-screen -mt-16 flex items-center justify-center">
                <LoadingSvg />
            </div>
        );
    }

    return (
        <div className="mt-4">
            <div className="flex flex-row justify-between items-center">
                <button
                    className="
                    h-16
                    w-20
                    flex flex-col items-center justify-center
                    btn bg-primary text-xs text-white
                    "
                    onClick={() => history.goBack()}
                    type="button"
                >
                    <ArrowLeftSvg />
                    Voltar
                </button>
                {user.role === 'admin' && (
                    <div className="flex flex-row space-x-2">
                        {partner.role !== 'admin' && (
                            <button
                                className="
                            h-16
                            w-20
                            flex flex-col items-center justify-center
                            btn bg-primary text-sm text-white
                        "
                                onClick={() => setModalActivitie(true)}
                                type="button"
                            >
                                <BankSvg />
                                Extrato
                            </button>
                        )}
                        {partner?.isActive ? (
                            <button
                                className="
                                h-16
                                w-20
                                flex flex-col items-center justify-center
                                btn bg-primary text-xs text-white
                            "
                                onClick={() => setModalDisable(true)}
                                type="button"
                            >
                                <DesactiveSvg />
                                Desativar
                            </button>
                        ) : (
                            <button
                                className="
                                 flex flex-col items-center justify-center
                                 btn bg-primary text-xs text-white
                             "
                                onClick={() => setModalActive(true)}
                                type="button"
                            >
                                <ActiveSvg />
                                Ativar
                            </button>
                        )}
                    </div>
                )}
            </div>
            <div className="flex flex-col justify-between items-center md:flex-row py-6">
                <div className="mb-6 md:mt-0 block md:hidden self-start">
                    <p className="text-gray-500 text-sm">
                        Criado em:{' '}
                        {partner
                            ? new Date(
                                  partner?.createdAt.seconds * 1000
                              ).toLocaleDateString('pt-BR')
                            : null}
                    </p>
                    <div className="inline-flex text-sm">
                        <p className="text-gray-500 ">Situação:</p>
                        {partner?.isActive ? (
                            <div className="ml-2 text-white bg-lime-400 px-2 rounded">
                                Ativo
                            </div>
                        ) : (
                            <div className="ml-2 text-white bg-red-400 px-2 rounded">
                                Desativado
                            </div>
                        )}
                    </div>
                </div>
                {partner.role === 'admin' ? (
                    <h2 className="text-2xl text-gray-500 self-center text-center">
                        Admin{' '}
                        <strong className="font-semibold text-gray-600 capitalize">
                            {partner?.name}
                        </strong>
                    </h2>
                ) : (
                    <h2 className="text-2xl text-gray-500 self-center text-center">
                        Parceiro{' '}
                        <strong className="font-semibold text-gray-600 capitalize">
                            {partner?.name}
                        </strong>
                    </h2>
                )}
                <div className="mt-4 md:mt-0 hidden md:block">
                    <p className="text-gray-500 text-sm">
                        Criado em:{' '}
                        {partner
                            ? new Date(
                                  partner?.createdAt.seconds * 1000
                              ).toLocaleDateString('pt-BR')
                            : null}
                    </p>
                    <div className="inline-flex text-sm">
                        <p className="text-gray-500 ">Situação:</p>
                        {partner?.isActive ? (
                            <div className="ml-2 text-white bg-lime-400 px-2 rounded">
                                Ativo
                            </div>
                        ) : (
                            <div className="ml-2 text-white bg-red-400 px-2 rounded">
                                Desativado
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {/** Saldo dísponivel */}
            <div className="flex flex-col space-y-2 sm:flex-row sm:space-x-4">
                {partner.role !== 'admin' && user.role === 'admin' && (
                    <div className="rounded px-2 py-4 flex items-center justify-center w-full bg-orange-100">
                        <p className="text-lg text-center text-gray-500 leading-snug">
                            Saldo a receber
                            <br />
                            <UINumber format="$ 0,0.00">
                                {partner?.currentBalance}
                            </UINumber>
                        </p>
                    </div>
                )}
                {user.role === 'admin' && (
                    <div className="rounded px-2 py-4 flex items-center justify-center w-full bg-green-100">
                        <p className="text-lg text-center text-gray-500 leading-snug">
                            Total pago
                            <br />
                            <UINumber format="$ 0,0.00">
                                {partner?.allPaidIndications}
                            </UINumber>
                        </p>
                    </div>
                )}
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                {/** Info do usuario */}
                <div className="py-4">
                    <h4 className="text-lg text-gray-500 capitalize">
                        Informações pessoais:
                    </h4>

                    <div className="mt-4 space-y-1 w-full">
                        <div className="bg-white inline-block py-1 w-full pl-4">
                            <span className="text-orange-500 text-sm">
                                Email
                            </span>
                            <p className="text-gray-600 text-sm">
                                {partner?.email}
                            </p>
                        </div>
                        <div className="bg-white inline-block py-1 w-full pl-4">
                            <span className="text-orange-500 text-sm">CPF</span>
                            <p className="text-gray-600 text-sm">
                                {partner?.cpf || '---'}
                            </p>
                        </div>
                        <div className="w-full inline-flex bg-white py-1 pl-4 space-x-6">
                            <div className="inline-block">
                                <span className="text-orange-500 text-sm">
                                    Cidade
                                </span>
                                <p className="text-gray-600 text-sm">
                                    {partner?.city || '---'}
                                </p>
                            </div>
                            <div className="bg-white inline-block">
                                <span className="text-orange-500 text-sm">
                                    Estado
                                </span>
                                <p className="text-gray-600 text-sm">
                                    {partner?.state || '---'}
                                </p>
                            </div>
                        </div>

                        <div className="bg-white inline-block py-1 w-full pl-4">
                            <span className="text-orange-500 text-sm">
                                Celular
                            </span>
                            <p className="text-gray-600 text-sm">
                                {partner?.phone || '---'}
                            </p>
                        </div>
                        <div className="bg-white inline-block py-1 w-full pl-4">
                            <span className="text-orange-500 text-sm">
                                Empresa
                            </span>
                            <p className="text-gray-600 text-sm">
                                {partner?.workplace || '---'}
                            </p>
                        </div>
                    </div>
                </div>

                <div className="py-4">
                    <h4 className="text-lg text-gray-500 capitalize">
                        Informações pagamento:
                    </h4>
                    <div className="mt-4 space-y-1 w-full">
                        <div className="bg-white inline-block py-1 w-full pl-4">
                            <span className="text-orange-500 text-sm">
                                Chave Pix1
                            </span>
                            <p className="text-gray-600 text-sm">
                                {partner?.payment_info?.pix_key1 || '---'}
                            </p>
                        </div>
                        <div className="bg-white inline-block py-1 w-full pl-4">
                            <span className="text-orange-500 text-sm">
                                Chave Pix2
                            </span>
                            <p className="text-gray-600 text-sm">
                                {partner?.payment_info?.pix_key2 || '---'}
                            </p>
                        </div>
                        <div className="bg-white inline-block py-1 w-full pl-4">
                            <span className="text-orange-500 text-sm">
                                Banco
                            </span>
                            <p className="text-gray-600 text-sm">
                                {partner?.payment_info?.bank || '---'}
                            </p>
                        </div>
                        <div className="bg-white inline-block py-1 w-full pl-4">
                            <span className="text-orange-500 text-sm">
                                Número agência
                            </span>
                            <p className="text-gray-600 text-sm">
                                {partner?.payment_info?.agency_number || '---'}
                            </p>
                        </div>
                        <div className="bg-white inline-block py-1 w-full pl-4">
                            <span className="text-orange-500 text-sm">
                                Numero da conta
                            </span>
                            <p className="text-gray-600 text-sm">
                                {partner?.payment_info?.account_number || '---'}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            {/** Buttons estados indicações */}
            {partner.role === 'user' && (
                <div className="py-6">
                    <h4 className="text-lg text-gray-500 capitalize">
                        Indicações:
                    </h4>
                    <TablePartners />
                    {/* <TesteTable /> */}
                </div>
            )}
            {modalDisable && (
                <ConfirmModal
                    type="danger"
                    onClose={setModalDisable}
                    onConfirm={handleDisablePartner}
                    title="Desativar parceiro"
                    description="Tem certeza que deseja desavitar parceiro?"
                    actionLabel="Confirmar"
                />
            )}
            {modalActive && (
                <ConfirmModal
                    type="success"
                    onClose={setModalActive}
                    onConfirm={handleActivePartner}
                    title="Ativar parceiro"
                    description="Tem certeza que deseja ativar parceiro?"
                    actionLabel="Confirmar"
                />
            )}
            {modalActivetie && <ActivitiesModal onClose={setModalActivitie} />}
        </div>
    );
};

export default ViewPartner;
