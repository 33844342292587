import React, { useState } from 'react';

import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import logo from '@/assets/images/logo-192.png';
import Button from '@/components/Button';
import Footer from '@/components/Footer';
import CustomInput from '@/components/Input';
import { passwordReset } from '@/services/api';
import forgotPassword from '@/utils/schemas/forgotPassword';

const ForgotPassword = () => {
    const [isSubmitting, setIsSubmiting] = useState(false);

    async function handleForgotPassword(email) {
        try {
            setIsSubmiting(true);
            await passwordReset(email);
            setIsSubmiting(false);
            toast.success('Solicitação realizada com sucesso');
        } catch (error) {
            setIsSubmiting(false);
            toast.error('Falha, tente novamente mais tarde');
        }
    }

    return (
        <section className="relative w-full h-full">
            <div className="container mx-auto px-4 h-full">
                <div className="flex content-center items-center justify-center h-full">
                    <div className="w-full lg:w-4/12 px-4 relative ">
                        <div className="mb-4 flex justify-center">
                            <img className="w-32 h-32" src={logo} alt="logo" />
                        </div>
                        <div className="flex flex-col min-w-0 break-words w-full mb-6 rounded-lg bg-transparent border-gray-400 border">
                            <div className="flex-auto px-4 lg:px-10 p-8">
                                <Formik
                                    enableReinitialize
                                    initialValues={{
                                        email: '',
                                    }}
                                    validationSchema={forgotPassword}
                                    onSubmit={({ email }) => {
                                        handleForgotPassword(email);
                                    }}
                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                    }) => (
                                        <form
                                            onSubmit={handleSubmit}
                                            noValidate
                                            autoComplete="off"
                                        >
                                            <CustomInput
                                                type="email"
                                                name="email"
                                                label="Email"
                                                placeholder="Email"
                                                value={values.email}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                helperText={
                                                    touched.email
                                                        ? errors.email
                                                        : ''
                                                }
                                                error={
                                                    touched.email &&
                                                    Boolean(errors.email)
                                                }
                                            />

                                            <Button
                                                loading={isSubmitting}
                                                disabled={isSubmitting}
                                                type="submit"
                                                customStyle="mt-4"
                                            >
                                                Solicitar
                                            </Button>
                                        </form>
                                    )}
                                </Formik>
                            </div>
                        </div>

                        <div className="flex mt-8 justify-center">
                            <Link to="/" className="text-gray-500">
                                <small>
                                    Possui conta?
                                    <strong className="text-gray-600 text-base">
                                        {' '}
                                        Voltar
                                    </strong>
                                </small>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </section>
    );
};

export default ForgotPassword;
