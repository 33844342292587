import React, { useState, useEffect } from 'react';

import { toast } from 'react-toastify';

import notFoundImage from '@/assets/images/notfound.svg';
import { ReactComponent as EyeSvg } from '@/assets/svgs/eye.svg';
import { ReactComponent as HistorySvg } from '@/assets/svgs/history.svg';
import { ReactComponent as LoadingSvg } from '@/assets/svgs/loading.svg';
import { ReactComponent as PaySvg } from '@/assets/svgs/pay.svg';
import Bedge from '@/components/Bedge';
import ConfirmModal from '@/components/Modals/ConfirmModal';
import IndicationHistoryModal from '@/components/Modals/IndicationHistoryModal';
import IndicationViewModal from '@/components/Modals/IndicationViewModal';
import Pagination from '@/components/Pagination';
import TooltipButton from '@/components/TooltipButton';
import {
    getUnpaidIndications,
    getUnpaidIndicationsNextPage,
    payIndication,
} from '@/services/api';

const header = [
    'Parceiro',
    'Nome/CPF',
    'Tipo',
    'Status',
    'DT criação',
    'DT atualização',
    'Ações',
];

const UnpaidIndications = () => {
    const [loading, setLoading] = useState(false);

    const [selectedIndication, setSelectedIndication] = useState(null);
    const [indications, setIndications] = useState([]);
    const [page, setPage] = useState(1);

    const [indicationConfirmModal, setIndicationConfirmPayModal] =
        useState(false);
    const [indicationHistoryModal, setIndicationHistoryModal] = useState(false);
    const [indicationViewModal, setIndicationViewModal] = useState(false);

    const limit = 10;

    async function handleGetIndications() {
        setLoading(true);
        const response = await getUnpaidIndications(limit);
        setIndications(response);
        setLoading(false);
    }

    async function handleConfirmIncidationPayment() {
        // criar função de pagamento e debitar do usuario
        const indicationId = selectedIndication.id;
        const { userId } = selectedIndication;

        try {
            await payIndication(userId, indicationId);

            const newData = {
                ...indications,
                total: Number(indications.total) - 1,
                data: indications.data.filter(
                    (indication) => indication.id !== indicationId
                ),
            };
            setIndications(newData);

            toast.success('Indicação paga com sucesso!');
        } catch (error) {
            toast.success('Falha so pagar indicação!');
        }
        setSelectedIndication(null);
        setIndicationConfirmPayModal(false);
    }

    function handleOpenIndicationHistoryModal(indication) {
        setSelectedIndication(indication);
        setIndicationHistoryModal(true);
    }

    function handleCloseIndicationHistoryModal() {
        setSelectedIndication(null);
        setIndicationHistoryModal(false);
    }

    function handleOpenIndicationViewModal(indication) {
        setSelectedIndication(indication);
        setIndicationViewModal(true);
    }

    function handleCloseIndicationViewModal() {
        setSelectedIndication(null);
        setIndicationViewModal(false);
    }

    function handleOpenConfirmPaymentModal(indication) {
        setSelectedIndication(indication);
        setIndicationConfirmPayModal(true);
    }

    function handleCloseConfirmPaymentModal() {
        setSelectedIndication(null);
        setIndicationConfirmPayModal(false);
    }

    async function handleNextPage() {
        setLoading(true);
        const response = await getUnpaidIndicationsNextPage(
            indications.data[indications.data.length - 1],
            limit
        );

        const newData = {
            ...response,
            total: Number(indications.total) + Number(response.total),
            data: [...indications.data, ...response.data],
        };

        setIndications(newData);
        setPage((prev) => prev + 1);
        setLoading(false);
    }

    useEffect(() => {
        handleGetIndications();
    }, []);

    return (
        <>
            <h2 className="my-6 text-2xl font-semibold text-gray-700">
                Lista de Indicações a Pagar
            </h2>
            <div className="w-full overflow-hidden rounded-lg shadow-xs">
                <div className="w-full overflow-x-auto">
                    <table className="w-full whitespace-no-wrap">
                        <thead>
                            <tr className="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b">
                                {header.map((item) => (
                                    <th key={item} className="px-4 py-3">
                                        {item}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y">
                            {indications?.data?.map((indication) => (
                                <tr
                                    key={indication.id}
                                    className="text-gray-700"
                                >
                                    <td className="px-4 py-3">
                                        <p className="font-medium text-gray-600 capitalize text-xs">
                                            {indication.partnerName}
                                        </p>
                                    </td>
                                    <td className="px-4 py-3">
                                        <p className="font-semibold capitalize text-xs">
                                            {indication.name}
                                        </p>
                                        <span className="text-gray-500 text-xs">
                                            {indication.cpf}
                                        </span>
                                    </td>
                                    <td className="px-4 py-3">
                                        <Bedge title={indication.type} />
                                    </td>
                                    <td className="px-4 py-3 text-sm">
                                        <Bedge title={indication.status} />
                                    </td>
                                    <td className="px-4 py-3 text-xs">
                                        {indication
                                            ? new Date(
                                                  indication.createdAt.seconds *
                                                      1000
                                              ).toLocaleDateString('pt-BR')
                                            : null}
                                    </td>
                                    <td className="px-4 py-3 text-xs">
                                        {indication
                                            ? new Date(
                                                  indication.updatedAt.seconds *
                                                      1000
                                              ).toLocaleDateString('pt-BR')
                                            : null}
                                    </td>
                                    <td className="px-4 py-3">
                                        <div className="flex items-center space-x-4 text-xs">
                                            <TooltipButton
                                                tip="visualizar"
                                                aria-label="view"
                                                onClick={() =>
                                                    handleOpenIndicationViewModal(
                                                        indication
                                                    )
                                                }
                                            >
                                                <EyeSvg />
                                            </TooltipButton>
                                            <TooltipButton
                                                tip="histórico"
                                                aria-label="history"
                                                onClick={() =>
                                                    handleOpenIndicationHistoryModal(
                                                        indication
                                                    )
                                                }
                                            >
                                                <HistorySvg />
                                            </TooltipButton>

                                            <TooltipButton
                                                tip="pagar"
                                                aria-label="pay"
                                                onClick={() =>
                                                    handleOpenConfirmPaymentModal(
                                                        indication
                                                    )
                                                }
                                            >
                                                <PaySvg />
                                            </TooltipButton>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                {loading && (
                    <div className="mt-2 w-full self-center flex items-center justify-center">
                        <LoadingSvg />
                    </div>
                )}
                {!loading && indications.data?.length <= 0 && (
                    <div className="my-12 flex flex-col w-full items-center justify-center">
                        <img
                            style={{ maxWidth: '250px' }}
                            src={notFoundImage}
                            alt="not found"
                        />
                        <span className="text-gray-500 text-sm mt-2">
                            Não encontramos indicações a pagar
                        </span>
                    </div>
                )}
            </div>
            {indications.data?.length > 0 && (
                <Pagination
                    handleNext={handleNextPage}
                    page={page}
                    hasMore={indications?.hasMore}
                />
            )}
            {indicationConfirmModal && (
                <ConfirmModal
                    type="danger"
                    actionLabel="Confirmar"
                    title="Confirmar Pagamento"
                    description="Deseja confirmar pagarmento de indicação?"
                    onClose={() => handleCloseConfirmPaymentModal()}
                    onConfirm={() => handleConfirmIncidationPayment()}
                />
            )}
            {indicationHistoryModal && (
                <IndicationHistoryModal
                    onClose={() => handleCloseIndicationHistoryModal()}
                    indication={selectedIndication}
                />
            )}
            {indicationViewModal && (
                <IndicationViewModal
                    onClose={() => handleCloseIndicationViewModal()}
                    indication={selectedIndication}
                />
            )}
        </>
    );
};

export default UnpaidIndications;
