import React from 'react';

function handleStatus(type) {
    switch (type) {
        case 'Análise':
            return 'bg-yellow-200 text-yellow-700';
        case 'Pendente':
            return 'bg-orange-200 text-orange-700';
        case 'Aprovado':
            return 'bg-green-200 text-green-700';
        case 'Cancelado':
            return 'bg-red-200 text-red-700';
        default:
            return 'bg-primary text-white';
    }
}

function Bedge({ title }) {
    return (
        <div
            className={`
            flex
            items-center
            justify-center
            p-1
            rounded
            text-xs
              ${handleStatus(title)}
            `}
        >
            {title}
        </div>
    );
}

export default Bedge;
