/* eslint-disable no-undef */
import React, { useEffect, useCallback } from 'react';

import notFoundImage from '@/assets/images/notfound.svg';
import { usePartner } from '@/contexts/PartnerContext';

const ModalHistory = ({ onClose, indication }) => {
    const {
        handleGetIndicationHistory,
        indicationHistory,
        loadingIndicationHistory,
    } = usePartner();

    useEffect(() => {
        async function initialLoading() {
            await handleGetIndicationHistory(indication.id);
        }
        initialLoading();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const downHandler = useCallback(
        (event) => {
            if (event.keyCode === 27) {
                onClose();
            }
        },
        [onClose]
    );

    useEffect(() => {
        document.addEventListener('keydown', downHandler, false);

        return () => {
            document.removeEventListener('keydown', downHandler, false);
        };
    }, [downHandler]);

    return (
        <div className="fixed z-20 inset-0 overflow-y-auto">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 transition-opacity">
                    <div className="absolute inset-0 bg-gray-500 opacity-75" />
                </div>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" />
                <div
                    className="inline-block align-bottom bg-white rounded-lg text-left
                        overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle
                        sm:max-w-lg sm:w-full"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline"
                >
                    <header
                        className="
                        flex flex-row items-center justify-between bg-primary py-2 px-4"
                    >
                        <h3
                            className="text-xl leading-6 font-medium text-white"
                            id="modal-headline"
                        >
                            Histórico da indicação
                        </h3>
                        <button
                            type="button"
                            onClick={onClose}
                            className="
                            flex items-center justify-center
                            h-8 w-8 bg-transparent
                            text-gray-600
                            bg-gray-100
                            rounded-lg
                            hover:opacity-80
                            transition duration-200 ease-in-out
                            outline-none focus:outline-none
                            "
                            style={{ top: '10px', right: '10px' }}
                        >
                            x
                        </button>
                    </header>
                    <div className="bg-white px-4 pb-4 sm:p-6 sm:pb-4">
                        <div className="sm:flex sm:items-start">
                            <div className="w-full overflow-auto h-64">
                                {/** HISTORIC START */}
                                <div className="relative w-1/2 m-8">
                                    <div
                                        className="border-r-2 border-gray-200 absolute h-full top-0"
                                        style={{ left: '11px' }}
                                    />
                                    <ul className="list-none m-0 p-0">
                                        {!loadingIndicationHistory &&
                                            indicationHistory
                                                .map((item, index) => (
                                                    <li
                                                        key={item.id}
                                                        className="mb-2"
                                                    >
                                                        <div className="flex flex-row items-center mb-1">
                                                            {index ===
                                                                indicationHistory.length -
                                                                    1 && (
                                                                <div className="bg-primary animate-ping rounded-full h-6 w-6 absolute" />
                                                            )}
                                                            <div className="bg-primary rounded-full h-6 w-6 z-10" />

                                                            <div className="ml-4">
                                                                <p className="text-base text-gray-700">
                                                                    {
                                                                        item.status
                                                                    }
                                                                </p>
                                                                <span className="text-gray-500 text-xs font-bold">
                                                                    {item
                                                                        ? new Date(
                                                                              item
                                                                                  .createdAt
                                                                                  .seconds *
                                                                                  1000
                                                                          ).toLocaleString(
                                                                              'pt-BR'
                                                                          )
                                                                        : null}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                ))
                                                .reverse()}
                                    </ul>
                                </div>
                                {!loadingIndicationHistory &&
                                    indicationHistory?.length <= 0 && (
                                        <div className="mt-4 flex flex-col w-full items-center justify-center">
                                            <img
                                                style={{
                                                    maxWidth: '200px',
                                                }}
                                                src={notFoundImage}
                                                alt="not found"
                                            />
                                            <span className="text-gray-500 text-sm mt-2">
                                                Não possui histórico
                                            </span>
                                        </div>
                                    )}
                                {/** HISTORIC END */}
                                {loadingIndicationHistory && (
                                    <div className="mt-4 w-full self-center flex items-center justify-center">
                                        <svg
                                            viewBox="0 0 100 100"
                                            enableBackground="new 0 0 0 0"
                                            xmlSpace="preserve"
                                            className="h-24 w-24 animate-spin"
                                        >
                                            <path
                                                fill="#EB7731"
                                                d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                                            />
                                        </svg>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalHistory;
