import React, { useEffect, useState } from 'react';

import { ReactComponent as MessageSvg } from '@/assets/svgs/message.svg';
import { ReactComponent as ToBePaidSvg } from '@/assets/svgs/tobepaid.svg';
import { ReactComponent as TotalSvg } from '@/assets/svgs/total.svg';
import UINumber from '@/components/UINumber';
import { useAuth } from '@/contexts/AuthContext';
import { getDashBoardData } from '@/services/api';

const Dashboard = () => {
    const { user } = useAuth();
    const [loading, setLoading] = useState(true);
    const [dashBoardData, setDashboardData] = useState(null);

    async function handleInidialLoading() {
        setLoading(true);
        const response = await getDashBoardData();
        setDashboardData(response);
        setLoading(false);
    }

    useEffect(() => {
        handleInidialLoading();
    }, []);

    if (loading) {
        return (
            <div className="w-full h-screen -mt-16 flex items-center justify-center">
                <svg
                    viewBox="0 0 100 100"
                    enableBackground="new 0 0 0 0"
                    xmlSpace="preserve"
                    className="h-32 w-32 animate-spin"
                >
                    <path
                        fill="#EB7731"
                        d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                    />
                </svg>
            </div>
        );
    }

    return (
        <>
            {user.role === 'admin' && (
                <h2 className="my-6 text-2xl font-semibold text-gray-700">
                    Dashboard
                </h2>
            )}
            {/* <!-- Cards --> */}
            <div className="inline-block w-full">
                {user.role === 'admin' && (
                    <div className="grid gap-6 grid-cols-1 sm:grid-cols-2">
                        {/* <!-- Card --> */}
                        <div className="flex items-center p-4 bg-white rounded-lg shadow-xs">
                            <div className="p-3 mr-4 text-orange-500 bg-orange-100 rounded-full">
                                <TotalSvg />
                            </div>
                            <div>
                                <p className="mb-2 text-sm font-medium text-gray-600">
                                    Total parceiros
                                </p>
                                <p className="text-lg font-semibold text-gray-700">
                                    {dashBoardData?.partners}
                                </p>
                            </div>
                        </div>
                        {/* <!-- Card --> */}
                        <div className="flex items-center p-4 bg-white rounded-lg shadow-xs">
                            <div className="p-3 mr-4 text-green-500 bg-green-100 rounded-full">
                                <ToBePaidSvg />
                            </div>
                            <div>
                                <p className="mb-2 text-sm font-medium text-gray-600">
                                    Comissões a pagar
                                </p>
                                <UINumber
                                    format="$ 0,0.00"
                                    customStyle="text-lg font-semibold text-gray-700"
                                >
                                    {dashBoardData.unpaidValue}
                                </UINumber>
                            </div>
                        </div>
                    </div>
                )}
                {/* <!-- Card --> */}
                <div className="inline-block w-full pb-6">
                    <h3 className="my-6 text-lg font-semibold text-gray-700 mb-6">
                        Propostas
                    </h3>
                    <div className="grid gap-6 grid-cols-1 sm:grid-cols-2">
                        <div className="flex items-center p-4 bg-white rounded-lg shadow-xs">
                            <div className="p-3 mr-4 text-orange-500 bg-orange-100  rounded-full">
                                <MessageSvg />
                            </div>
                            <div>
                                <p className="mb-2 text-sm font-medium text-gray-600">
                                    Pendentes
                                </p>
                                <p className="text-lg font-semibold text-gray-700">
                                    {dashBoardData?.pendente}
                                </p>
                            </div>
                        </div>
                        <div className="flex items-center p-4 bg-white rounded-lg shadow-xs">
                            <div className="p-3 mr-4 text-yellow-500 bg-yellow-100  rounded-full">
                                <MessageSvg />
                            </div>
                            <div>
                                <p className="mb-2 text-sm font-medium text-gray-600">
                                    Análise
                                </p>
                                <p className="text-lg font-semibold text-gray-700">
                                    {dashBoardData?.analise}
                                </p>
                            </div>
                        </div>
                        <div className="flex items-center p-4 bg-white rounded-lg shadow-xs">
                            <div className="p-3 mr-4 text-green-500 bg-green-100  rounded-full">
                                <MessageSvg />
                            </div>
                            <div>
                                <p className="mb-2 text-sm font-medium text-gray-600">
                                    Aprovadas
                                </p>
                                <p className="text-lg font-semibold text-gray-700">
                                    {dashBoardData?.aprovado}
                                </p>
                            </div>
                        </div>
                        <div className="flex items-center p-4 bg-white rounded-lg shadow-xs">
                            <div className="p-3 mr-4 text-red-500 bg-red-100  rounded-full">
                                <MessageSvg />
                            </div>
                            <div>
                                <p className="mb-2 text-sm font-medium text-gray-600">
                                    Canceladas
                                </p>
                                <p className="text-lg font-semibold text-gray-700">
                                    {dashBoardData?.cancelado}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Dashboard;
