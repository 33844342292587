import React, { useState, useRef } from 'react';

import { useAuth } from '@/contexts/AuthContext';
import useClickOutside from '@/hooks/useClickOutside';
import { logout } from '@/services/api';

export default function Profile() {
    const { user } = useAuth();
    const [isOpen, setIsOpen] = useState(false);

    const profileRef = useRef(null);
    const buttonRef = useRef(null);

    useClickOutside(profileRef, buttonRef, handleToggleProfile);

    function handleToggleProfile() {
        setIsOpen((prev) => !prev);
    }

    function handleLogout() {
        logout();
    }

    return (
        <li className="relative">
            <button
                ref={buttonRef}
                className="h-8 w-8 align-middle rounded-full bg-orange-400 text-white hover:bg-primaryHover transition ease-in duration-150 focus:outline-none"
                onClick={() => setIsOpen((prev) => !prev)}
                aria-label="Account"
                aria-haspopup="true"
                type="button"
            >
                <h4 className="uppercase">{user.name.charAt(0)}</h4>
            </button>
            {isOpen && (
                <ul
                    ref={profileRef}
                    className="absolute right-0 w-56 p-2 mt-2 space-y-2 text-gray-600 bg-white border border-gray-100 rounded-md shadow-md"
                    aria-label="submenu"
                >
                    <li className="flex">
                        <button
                            className="inline-flex items-center w-full px-2 py-1 text-sm font-semibold transition-colors duration-150 rounded-md hover:bg-gray-100 hover:text-gray-800"
                            onClick={handleLogout}
                            type="button"
                        >
                            <svg
                                className="w-4 h-4 mr-3"
                                aria-hidden="true"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1" />
                            </svg>
                            <span>Log out</span>
                        </button>
                    </li>
                </ul>
            )}
        </li>
    );
}
