import React, { useContext, useState, createContext } from 'react';

import { toast } from 'react-toastify';

import {
    getPartnerActivities,
    getPartner,
    getPartnerIndications,
    getIndicationHistory,
    setDisablePartner,
    setActivePartner,
    payIndication,
} from '@/services/api';

const PartnerContext = createContext();

export function usePartner() {
    return useContext(PartnerContext);
}

export function PartnerProvider({ children }) {
    const [loading, setLoading] = useState(true);
    const [loadingIndications, setLoadingIndications] = useState(true);
    const [loadingActivities, setLoadingActivities] = useState(false);
    const [loadingIndicationHistory, setLoadingIndicationHistory] =
        useState(false);
    const [modalActivetie, setModalActivitie] = useState(false);

    const [partner, setPartner] = useState(null);
    const [indications, setIndications] = useState([]);
    const [indicationHistory, setIndicationHistory] = useState([]);
    const [activities, setActivities] = useState([]);

    const [modalDisable, setModalDisable] = useState(false);
    const [modalActive, setModalActive] = useState(false);

    const defaultTable = [
        { label: 'Pendente', state: true },
        { label: 'Análise', state: false },
        { label: 'Aprovado', state: false },
        { label: 'Cancelado', state: false },
    ];

    const [tableIsActive, setTableIsActive] = useState(defaultTable);

    async function handleGetPartner(id) {
        setLoading(true);
        const response = await getPartner(id);
        setPartner(response);
        setLoading(false);
    }

    async function handleDisablePartner() {
        try {
            setModalDisable(false);
            setDisablePartner(partner.id);
            setPartner({
                ...partner,
                isActive: false,
            });
            toast.success('Parceiro desativado com sucesso');
        } catch (error) {
            toast.success('Falha oa desativar parceiro');
            setModalDisable(false);
        }
    }

    async function handleActivePartner() {
        try {
            setActivePartner(partner.id);
            setPartner({
                ...partner,
                isActive: true,
            });
            toast.success('Parceiro ativado com sucesso');
            setModalActive(false);
        } catch (error) {
            toast.success('Falha oa ativar parceiro');
            setModalActive(false);
        }
    }

    async function handleGetIndications(status) {
        setLoadingIndications(true);
        setTableIsActive((prev) =>
            prev.map((item) => {
                if (item.label === status) {
                    return { ...item, state: true };
                }
                return { ...item, state: false };
            })
        );
        const response = await getPartnerIndications(
            partner && partner.id,
            status
        );
        setIndications(response);
        setLoadingIndications(false);
    }

    function handlePartnerBonusValue(type = 'sum', bonusValue) {
        const indicationBonus = parseFloat(bonusValue);
        const partnerCurrentBonus = parseFloat(partner?.currentBalance) || 0;
        let bonusToReceive = 0;

        if (type === 'sum') {
            bonusToReceive = indicationBonus + partnerCurrentBonus;
            setPartner({ ...partner, currentBalance: bonusToReceive });
        } else {
            bonusToReceive = partnerCurrentBonus - indicationBonus;
            const allPaidIndications =
                parseFloat(partner?.allPaidIndications) || 0;
            const totalPaid = allPaidIndications + indicationBonus;

            setPartner({
                ...partner,
                currentBalance: bonusToReceive,
                allPaidIndications: totalPaid,
            });
        }
    }

    async function handleGetIndicationHistory(indicationID) {
        setLoadingIndicationHistory(true);
        const response = await getIndicationHistory(indicationID);
        setIndicationHistory(response);
        setLoadingIndicationHistory(false);
    }

    async function handleGetActivities() {
        setLoadingActivities(true);
        const response = await getPartnerActivities(partner.id);
        setActivities(response);
        setLoadingActivities(false);
    }

    async function handlePayIndication(userID, indicationID, bonusValue) {
        try {
            await payIndication(userID, indicationID);
            setIndications((prev) =>
                prev.map((indication) =>
                    indication.id === indicationID
                        ? { ...indication, isPaid: true }
                        : { ...indication }
                )
            );
            handlePartnerBonusValue('sub', bonusValue);
        } catch (err) {
            throw new Error('Falha ao pagar indicação');
        }
    }

    const value = {
        loading,
        loadingIndications,
        loadingIndicationHistory,
        loadingActivities,
        partner,
        indications,
        indicationHistory,
        activities,
        setActivities,
        tableIsActive,
        modalActivetie,
        setModalActivitie,
        modalDisable,
        modalActive,
        setModalDisable,
        setModalActive,
        handleGetPartner,
        handleDisablePartner,
        handleActivePartner,
        handleGetIndications,
        handlePartnerBonusValue,
        handleGetIndicationHistory,
        handleGetActivities,
        handlePayIndication,
    };

    return (
        <PartnerContext.Provider value={value}>
            {children}
        </PartnerContext.Provider>
    );
}
