import React from 'react';

const Select = ({
    name,
    label,
    value,
    options,
    onChange,
    error,
    helperText,
    selectionState = null,
}) => {
    return (
        <div>
            <label
                htmlFor={name}
                className="block uppercase text-gray-700 text-xs font-bold mt-4"
            >
                <span className="text-gray-600">{label}</span>
            </label>
            <select
                id={name}
                className="
                rounded mt-1 block w-full
                border-1
                border-gray-400
                focus:ring-1
                focus:ring-primaryHover
                focus:border-primaryHover
                "
                name={name}
                value={value}
                onChange={(e) => {
                    onChange(`${name}`, e.target.value);
                    selectionState(e.target.value);
                    if (e.target.value !== 'Aprovado') {
                        onChange('selectedComissao', 0);
                    }
                }}
            >
                <option disabled value="">
                    Selecione uma opção
                </option>
                {options.map((option) => {
                    return (
                        <option key={option.id} value={option.value}>
                            {option.label}
                        </option>
                    );
                })}
            </select>
            <p
                className="text-red-500 text-xs mt-1"
                style={{ visibility: `${error ? 'visible' : 'hidden'}` }}
            >
                {helperText || `xxx`}
            </p>
        </div>
    );
};

export default Select;
