import React, { useContext, useState, createContext } from 'react';

import history from '@/services/history';

const SearchContext = createContext();

export function useSearch() {
    return useContext(SearchContext);
}

export function SearchProvider({ children }) {
    const [search, setSearch] = useState('');
    const [error, setError] = useState(false);

    function handleClear() {
        setSearch('');
        setError(false);
        history.push(`/partners`);
    }

    function handleClearField() {
        setSearch('');
        setError(false);
    }

    function handleSearch() {
        if (search.length > 0) {
            if (search.length >= 3) {
                history.push(`/partners?name=${search}`);
            } else {
                setError(true);
            }
        } else {
            setError(true);
        }
    }

    function handleEnter(e) {
        if (e.keyCode === 13) {
            handleSearch();
        }
    }

    const value = {
        search,
        setSearch,
        error,
        handleEnter,
        handleSearch,
        handleClearField,
        handleClear,
    };

    return (
        <SearchContext.Provider value={value}>
            {children}
        </SearchContext.Provider>
    );
}
