import React, { useState } from 'react';

import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import logo from '@/assets/images/logo-192.png';
import Button from '@/components/Button';
import Footer from '@/components/Footer';
import CustomInput from '@/components/Input';
import { loginWithEmail } from '@/services/api';
import signInSchema from '@/utils/schemas/signIn';

const SignIn = () => {
    const [loading, setLoading] = useState(false);

    async function handleSignIn(email, password) {
        try {
            setLoading(true);
            await loginWithEmail(email, password);
            setLoading(false);
            toast.success('Seja bem-vindo');
        } catch (error) {
            setLoading(false);
            toast.error(error.message);
        }
    }

    return (
        <section className="relative w-full h-full">
            <div className="container mx-auto px-4 h-full">
                <div className="flex content-center items-center justify-center h-full">
                    <div className="w-full lg:w-4/12 px-4">
                        <div className="mb-4 flex justify-center">
                            <img className="w-32 h-32" src={logo} alt="logo" />
                        </div>
                        <div className="flex flex-col min-w-0 break-words w-full mb-6 rounded-lg bg-transparent border-gray-300 border">
                            <div className="flex-auto p-8">
                                <Formik
                                    enableReinitialize
                                    initialValues={{
                                        email: '',
                                        password: '',
                                        remember: false,
                                    }}
                                    validationSchema={signInSchema}
                                    onSubmit={({ email, password }) => {
                                        handleSignIn(email, password);
                                    }}
                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                    }) => (
                                        <form
                                            onSubmit={handleSubmit}
                                            noValidate
                                            autoComplete="off"
                                        >
                                            <CustomInput
                                                type="email"
                                                name="email"
                                                label="Email"
                                                placeholder="Email"
                                                value={values.email}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                helperText={
                                                    touched.email
                                                        ? errors.email
                                                        : ''
                                                }
                                                error={
                                                    touched.email &&
                                                    Boolean(errors.email)
                                                }
                                            />

                                            <CustomInput
                                                type="password"
                                                name="password"
                                                label="Senha"
                                                placeholder="Digite sua senha"
                                                value={values.password}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                helperText={
                                                    touched.password
                                                        ? errors.password
                                                        : ''
                                                }
                                                error={
                                                    touched.password &&
                                                    Boolean(errors.password)
                                                }
                                            />

                                            <Button
                                                loading={loading}
                                                disabled={loading}
                                                type="submit"
                                                customStyle="mt-4"
                                            >
                                                Logar
                                            </Button>
                                        </form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                        <div className="flex justify-end ">
                            <Link
                                to="forgotpassword"
                                className="text-gray-500 text-lg"
                            >
                                <small>Esqueceu senha?</small>
                            </Link>
                        </div>
                        {/*  <div className="flex mt-8 justify-center">
                            <Link to="/signup" className="text-gray-500">
                                <small>
                                    Não possui conta?{' '}
                                    <strong className="text-gray-600 text-base">
                                        Criar
                                    </strong>
                                </small>
                            </Link>
                        </div> */}
                    </div>
                </div>
            </div>
            <Footer />
        </section>
    );
};

export default SignIn;
