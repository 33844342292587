/* eslint-disable no-undef */
import React, { useEffect, useCallback } from 'react';

import notFoundImage from '@/assets/images/notfound.svg';
import { ReactComponent as LoadingSvg } from '@/assets/svgs/loading.svg';
import { ReactComponent as MoneyInSvg } from '@/assets/svgs/money-in.svg';
import { ReactComponent as MoneyOutSvg } from '@/assets/svgs/money-out.svg';
import UINumber from '@/components/UINumber';
import { usePartner } from '@/contexts/PartnerContext';

const ModalActivities = ({ onClose }) => {
    const { handleGetActivities, loadingActivities, activities } = usePartner();

    useEffect(() => {
        async function initialLoading() {
            await handleGetActivities();
        }
        initialLoading();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    function handleClose() {
        onClose(false);
    }

    const downHandler = useCallback(
        (event) => {
            if (event.keyCode === 27) {
                handleClose();
            }
        },
        [onClose] // eslint-disable-line react-hooks/exhaustive-deps
    );

    useEffect(() => {
        document.addEventListener('keydown', downHandler, false);

        return () => {
            document.removeEventListener('keydown', downHandler, false);
        };
    }, [downHandler]);

    return (
        <div className="fixed z-20 inset-0 overflow-y-auto">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 transition-opacity">
                    <div className="absolute inset-0 bg-gray-500 opacity-75" />
                </div>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" />
                <div
                    className="inline-block align-bottom bg-white rounded-lg text-left
                        overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle
                        sm:max-w-lg sm:w-full"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline"
                >
                    <header
                        className="
                        flex flex-row items-center justify-between bg-primary py-2 px-4"
                    >
                        <h3
                            className="text-xl leading-6 font-medium text-white"
                            id="modal-headline"
                        >
                            Histórico de Atividades
                        </h3>
                        <button
                            type="button"
                            onClick={handleClose}
                            className="
                            flex items-center justify-center
                            h-8 w-8 bg-transparent
                            text-gray-600
                            bg-gray-100
                            rounded-lg
                            hover:opacity-80
                            transition duration-200 ease-in-out
                            outline-none focus:outline-none
                            "
                            style={{ top: '10px', right: '10px' }}
                        >
                            x
                        </button>
                    </header>
                    <div className="bg-white px-4 pb-4 sm:p-6 sm:pb-4">
                        <div className="sm:flex sm:items-start">
                            <div className="w-full overflow-auto h-64">
                                {/** HISTORIC START */}
                                <div className="relative m-8">
                                    <ul className="list-none m-0 p-0">
                                        {!loadingActivities &&
                                            activities.map((activity) => (
                                                <li
                                                    key={activity.id}
                                                    className="w-fullfull inline-flex items-center border-b pb-2 mb-2"
                                                >
                                                    <div className="h-16 w-16 flex items-center justify-center rounded-full bg-gray-100 border-2">
                                                        {activity.type ===
                                                        'deposit' ? (
                                                            <MoneyInSvg />
                                                        ) : (
                                                            <MoneyOutSvg />
                                                        )}
                                                    </div>
                                                    <div className="flex flex-col ml-6">
                                                        <span className="block text-gray-400 leading-normal text-xs font-normal mt-1">
                                                            operação:{' '}
                                                            <strong className="font-medium text-gray-500">
                                                                {
                                                                    activity.indicationId
                                                                }
                                                            </strong>
                                                        </span>
                                                        {activity.type ===
                                                        'deposit' ? (
                                                            <p className="text-sm text-gray-700 leading-normal">
                                                                Recebeu crédito
                                                                de indicação
                                                            </p>
                                                        ) : (
                                                            <p className="text-sm text-gray-700 leading-normal">
                                                                Dinheiro
                                                                transferido
                                                            </p>
                                                        )}
                                                        <UINumber
                                                            format="$ 0,0.00"
                                                            customStyle="text-sm text-gray-700 font-medium"
                                                        >
                                                            {activity.value}
                                                        </UINumber>

                                                        <span className="block text-gray-400 text-xs font-normal mt-1 leading-normal">
                                                            {activity.createdAt}
                                                        </span>
                                                    </div>
                                                </li>
                                            ))}
                                    </ul>
                                </div>
                                {!loadingActivities && activities?.length <= 0 && (
                                    <div className="mt-4 flex flex-col w-full items-center justify-center">
                                        <img
                                            style={{
                                                maxWidth: '200px',
                                            }}
                                            src={notFoundImage}
                                            alt="not found"
                                        />
                                        <span className="text-gray-500 text-sm mt-2">
                                            Não possui atividades recentes
                                        </span>
                                    </div>
                                )}
                                {/** HISTORIC END */}
                                {loadingActivities && (
                                    <div className="mt-4 w-full self-center flex items-center justify-center">
                                        <LoadingSvg />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalActivities;
