/* eslint-disable no-undef */
import React, { useEffect, useCallback } from 'react';

import Button from '@/components/Button';

const ConfirmModal = ({
    type = 'default',
    actionLabel = 'Deletar',
    title,
    description,
    onClose,
    onConfirm,
}) => {
    const backgroundStyle = () => {
        switch (type) {
            case 'success':
                return 'bg-lime-500';
            case 'danger':
                return 'bg-rose-500';
            case 'alert':
                return 'bg-orange-500';
            default:
                return 'bg-blue-500';
        }
    };

    function handleClose() {
        onClose(false);
    }

    const downHandler = useCallback(
        (event) => {
            if (event.keyCode === 27) {
                handleClose();
            }
        },
        [onClose] // eslint-disable-line react-hooks/exhaustive-deps
    );

    useEffect(() => {
        document.addEventListener('keydown', downHandler, false);

        return () => {
            document.removeEventListener('keydown', downHandler, false);
        };
    }, [downHandler]);

    return (
        <div className="fixed z-20 inset-0 overflow-y-auto">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 transition-opacity">
                    <div className="absolute inset-0 bg-gray-500 opacity-75" />
                </div>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" />
                <div
                    className="inline-block align-bottom bg-white rounded-lg text-left
                    overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle
                    sm:max-w-lg sm:w-full"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline"
                >
                    <header
                        className={`flex flex-row items-center justify-between py-2 px-4 ${backgroundStyle(
                            type
                        )}`}
                    >
                        <h3
                            className="text-xl leading-6 font-medium text-white"
                            id="modal-headline"
                        >
                            {title}
                        </h3>
                        <button
                            type="button"
                            onClick={handleClose}
                            className="
                            flex items-center justify-center
                            h-8 w-8 bg-transparent
                            text-gray-600
                            bg-gray-100
                            rounded-lg
                            hover:opacity-80
                            transition duration-200 ease-in-out
                            outline-none focus:outline-none
                            "
                            style={{ top: '10px', right: '10px' }}
                        >
                            x
                        </button>
                    </header>
                    <div className="bg-white px-4 pb-4 sm:p-6 sm:pb-4">
                        <div className="sm:flex sm:items-start">
                            <div className="w-full overflow-auto h-4/6">
                                {/** MODAL BODY */}
                                <div className="mt-2">
                                    <p className="text-sm leading-5 text-gray-500">
                                        {description}
                                    </p>
                                </div>
                                <div className="bg-gray-50 mt-8 sm:flex sm:flex-row-reverse">
                                    <span className="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto space-x-2">
                                        <Button
                                            variant="secondary"
                                            onClick={handleClose}
                                        >
                                            Cancelar
                                        </Button>
                                        <Button
                                            variant="success"
                                            onClick={onConfirm}
                                        >
                                            {actionLabel}
                                        </Button>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConfirmModal;
