import React, { useState, useEffect } from 'react';

import notFoundImage from '@/assets/images/notfound.svg';
import { ReactComponent as EditSvg } from '@/assets/svgs/edit.svg';
import { ReactComponent as EyeSvg } from '@/assets/svgs/eye.svg';
import { ReactComponent as HistorySvg } from '@/assets/svgs/history.svg';
import { ReactComponent as LoadingSvg } from '@/assets/svgs/loading.svg';
import { ReactComponent as SearchSvg } from '@/assets/svgs/search.svg';
import Bedge from '@/components/Bedge';
import IndicationHistoryModal from '@/components/Modals/IndicationHistoryModal';
import IndicationStatusModal from '@/components/Modals/IndicationStatusModal';
import IndicationViewModal from '@/components/Modals/IndicationViewModal';
import Pagination from '@/components/Pagination';
import TooltipButton from '@/components/TooltipButton';
import {
    getIndications,
    getIndicationsNextPage,
    getIndicationBySearch,
} from '@/services/api';

const Indications = () => {
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [indications, setIndications] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState('Pendente');
    const [selectedIndication, setSelectedIndication] = useState(null);
    const [indicationModal, setIndicationModal] = useState(false);
    const [indicationHistoryModal, setIndicationHistoryModal] = useState(false);
    const [indicationViewModal, setIndicationViewModal] = useState(false);
    const [sort, setSort] = useState('desc');

    const [search, setSearch] = useState('');
    const [isSearch, setIsSearch] = useState(false);
    const [error, setError] = useState(false);

    const limit = 20;

    const header = [
        'Nome/CPF',
        'Telefone',
        'Parceiro',
        'Status',
        'DT criação',
        'DT atualização',
        'Ações',
    ];
    const options = [
        { id: 1, value: 'Pendente', label: 'Pendente' },
        { id: 2, value: 'Análise', label: 'Análise' },
        { id: 3, value: 'Aprovado', label: 'Aprovado' },
        { id: 4, value: 'Cancelado', label: 'Cancelado' },
    ];

    async function handleGetIndications() {
        setLoading(true);

        setSearch('');
        setError(false);
        setIsSearch(false);

        const response = await getIndications(selectedStatus, sort, limit);
        setIndications(response);
        setLoading(false);
    }

    function handleSelectedIndication(indication) {
        setSelectedIndication(indication);
        setIndicationModal(true);
    }

    function handleCloseIndicationModal() {
        setIndicationModal(false);
        setSelectedIndication(null);
    }

    function handleOpenIndicationHistoryModal(indication) {
        setSelectedIndication(indication);
        setIndicationHistoryModal(true);
    }

    function handleCloseIndicationHistoryModal() {
        setSelectedIndication(null);
        setIndicationHistoryModal(false);
    }

    function handleOpenIndicationViewModal(indication) {
        setSelectedIndication(indication);
        setIndicationViewModal(true);
    }

    function handleCloseIndicationViewModal() {
        setSelectedIndication(null);
        setIndicationViewModal(false);
    }

    function handleConfirmStatusChange() {
        setIndicationModal(false);
        setSelectedIndication(null);
        handleGetIndications();
    }

    async function handleNextPage() {
        setLoading(true);
        const response = await getIndicationsNextPage(
            indications.data[indications.data.length - 1],
            selectedStatus,
            sort,
            limit
        );

        const newData = {
            ...response,
            total: Number(indications.total) + Number(response.total),
            data: [...indications.data, ...response.data],
        };

        setIndications(newData);
        setPage((prev) => prev + 1);
        setLoading(false);
    }

    function handleClearField() {
        setSearch('');
        setError(false);
        setIsSearch(false);
        handleGetIndications();
    }

    async function handleSearch() {
        if (search.length > 0) {
            if (search.length >= 3) {
                // pesquisar
                setLoading(true);
                setIsSearch(true);
                const response = await getIndicationBySearch(search);
                setIndications(response);
                setLoading(false);
            } else {
                setError(true);
            }
        } else {
            setError(true);
        }
    }

    function handleEnter(e) {
        if (e.keyCode === 13) {
            handleSearch();
        }
    }

    function handleSortIndications() {
        setSort((prev) => (prev === 'desc' ? 'asc' : 'desc'));
    }

    useEffect(() => {
        handleGetIndications();
    }, [selectedStatus, sort]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <h2 className="mt-6 text-2xl font-semibold text-gray-700">
                Lista de Indicações
            </h2>

            {/* <!--    <!-- With actions -- --> */}
            <div className="w-full flex flex-wrap items-center justify-between my-4 space-y-4 lg:space-y-0">
                <div className="inline-flex w-full md:max-w-md md:w-7/12 ">
                    <div className="relative w-full mr-2 sm:mr-4">
                        <div className="absolute inset-y-0 flex items-center pl-2 text-orange-400">
                            <SearchSvg />
                        </div>

                        <input
                            className={`
                                    h-8 w-full pl-8 pr-2
                                    placeholder-gray-400
                                    text-gray-700
                                    rounded
                                    text-sm
                                    shadow
                                    focus:outline-none
                                    focus:ring-0
                                    focus:ring-primaryHover
                                    focus:border-primaryHover
                                    leading-tight
                                    ${error && 'border-2 border-red-400 '}
                                    `}
                            type="text"
                            placeholder="Digite o CPF ou Nome"
                            aria-label="Search"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            onKeyDown={(e) => handleEnter(e)}
                        />
                        {search.length > 0 && (
                            <div
                                className="absolute
                                inset-y-0
                                flex
                                items-center
                                right-2
                                "
                            >
                                <button
                                    className="
                                    text-gray-500
                                    text-lg
                                    px-2
                                    rounded-full
                                    outline-none
                                    focus:outline-none
                                    bg-gray-100"
                                    type="button"
                                    onClick={handleClearField}
                                >
                                    x
                                </button>
                            </div>
                        )}
                    </div>
                    <button
                        className="
                        btn
                        bg-primary
                        inline-flex
                        justify-center
                        items-center
                        text-white
                        text-xs
                        rounded px-4
                        "
                        type="button"
                        onClick={handleSearch}
                    >
                        <SearchSvg className="w-4 h-4 mr-1" />
                        <span className="hidden md:block">Pesquisar</span>
                    </button>
                </div>

                <div className="flex flex-row space-x-4">
                    <div className="flex flex-1 flex-col">
                        <span className="text-xs text-gray-700">Ordenar</span>
                        <button
                            className="
                            btn
                            bg-primary
                            text-white
                            justify-center
                            items-center
                            text-xs
                            rounded
                            w-28"
                            type="button"
                            onClick={handleSortIndications}
                        >
                            {sort === 'desc' ? 'Crescente' : 'Decrescente'}
                        </button>
                    </div>

                    <select
                        className="
                        w-full
                        md:w-40
                        rounded
                        border-1
                        border-gray-400
                        focus:ring-1
                        focus:ring-primaryHover
                        focus:border-primaryHover
                        text-xs
                    "
                        value={selectedStatus}
                        onChange={(e) => setSelectedStatus(e.target.value)}
                    >
                        {options.map((option) => (
                            <option key={option.id} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="w-full overflow-hidden rounded-lg shadow-xs">
                <div className="w-full overflow-x-auto">
                    <table className="w-full whitespace-no-wrap">
                        <thead>
                            <tr className="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b">
                                {header.map((item) => (
                                    <th key={item} className="px-4 py-3">
                                        {item}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y">
                            {indications?.data?.map((indication) => (
                                <tr
                                    key={indication.id}
                                    className={`text-gray-700
                                        ${
                                            indication.isPaid
                                                ? 'bg-lime-200'
                                                : 'bg-transparent'
                                        }
                                    `}
                                >
                                    <td className="px-4 py-3">
                                        <p className="font-semibold capitalize text-sm">
                                            {indication.name}
                                        </p>
                                        <span className="text-gray-500 text-xs">
                                            {indication.cpf}
                                        </span>
                                    </td>
                                    <td className="px-4 py-3 text-xs">
                                        {indication.phone}
                                    </td>
                                    <td className="px-4 py-3 text-xs">
                                        {indication.partnerName}
                                    </td>
                                    <td className="px-4 py-3 text-xs">
                                        <Bedge title={indication.status} />
                                    </td>
                                    <td className="px-4 py-3 text-xs">
                                        {indication
                                            ? new Date(
                                                  indication.createdAt.seconds *
                                                      1000
                                              ).toLocaleDateString('pt-BR')
                                            : null}
                                    </td>
                                    <td className="px-4 py-3 text-xs">
                                        {indication
                                            ? new Date(
                                                  indication.updatedAt.seconds *
                                                      1000
                                              ).toLocaleDateString('pt-BR')
                                            : null}
                                    </td>
                                    <td className="px-4 py-3">
                                        <div className="flex items-center space-x-4 text-xs">
                                            <TooltipButton
                                                tip="visualizar"
                                                aria-label="view"
                                                onClick={() =>
                                                    handleOpenIndicationViewModal(
                                                        indication
                                                    )
                                                }
                                            >
                                                <EyeSvg />
                                            </TooltipButton>
                                            <TooltipButton
                                                tip="histórico"
                                                aria-label="view"
                                                onClick={() =>
                                                    handleOpenIndicationHistoryModal(
                                                        indication
                                                    )
                                                }
                                            >
                                                <HistorySvg />
                                            </TooltipButton>

                                            {selectedStatus !== 'Aprovado' &&
                                                indication.status !==
                                                    'Aprovado' && (
                                                    <TooltipButton
                                                        tip="editar"
                                                        aria-label="edit"
                                                        onClick={() =>
                                                            handleSelectedIndication(
                                                                indication
                                                            )
                                                        }
                                                    >
                                                        <EditSvg />
                                                    </TooltipButton>
                                                )}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                {loading && (
                    <div className="mt-2 w-full self-center flex items-center justify-center">
                        <LoadingSvg />
                    </div>
                )}
                {!loading && indications.data?.length <= 0 && (
                    <div className="my-12 flex flex-col w-full items-center justify-center">
                        <img
                            style={{ maxWidth: '250px' }}
                            src={notFoundImage}
                            alt="not found"
                        />
                        <span className="text-gray-500 text-sm mt-2">
                            Não encontramos indicações
                        </span>
                    </div>
                )}
                {indications.data?.length > 0 && !isSearch && (
                    <Pagination
                        handleNext={handleNextPage}
                        page={page}
                        hasMore={indications?.hasMore}
                    />
                )}
            </div>
            {indicationModal && (
                <IndicationStatusModal
                    onClose={() => handleCloseIndicationModal()}
                    title="Alterar Indicação"
                    actionLabel="Confirmar"
                    selectedIndication={selectedIndication}
                    onConfirm={handleConfirmStatusChange}
                />
            )}
            {indicationHistoryModal && (
                <IndicationHistoryModal
                    onClose={() => handleCloseIndicationHistoryModal()}
                    indication={selectedIndication}
                />
            )}
            {indicationViewModal && (
                <IndicationViewModal
                    onClose={() => handleCloseIndicationViewModal()}
                    indication={selectedIndication}
                />
            )}
        </>
    );
};

export default Indications;
