import { formatDistance } from 'date-fns';
import { pt } from 'date-fns/locale';
import firebase from 'firebase/app';

import formatMoney from '@/utils/formatMoney';
import { formatCpf, hasNumber } from '@/utils/helpers';
import 'firebase/auth';
import 'firebase/firestore';

import apiKeys from './firebase';

// Initialize Firebase App
if (!firebase.apps.length) {
    firebase.initializeApp(apiKeys.firebaseConfig);
}

export const auth = firebase.auth();

const db = firebase.firestore();

export const loginWithEmail = async (email, password) => {
    let authUser = null;

    try {
        await firebase
            .firestore()
            .collection('users')
            .where('email', '==', email)
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    authUser = doc.data();
                });
            });
    } catch (error) {
        throw new Error('Email ou senha inválido');
    }

    if (!authUser) {
        throw new Error('Email ou senha inválido');
    } else if (authUser.isActive && authUser.role !== 'user') {
        try {
            const user = await auth.signInWithEmailAndPassword(email, password);
            return user;
        } catch (error) {
            throw new Error('Email ou senha inválido');
        }
    } else {
        throw new Error('Usuário sem permissão');
    }
};

export const registerWithEmail = async (email, password, name) => {
    try {
        await auth.createUserWithEmailAndPassword(email, password);
        const { currentUser } = auth;

        db.collection('users').doc(currentUser.uid).set({
            id: currentUser.uid,
            email: currentUser.email,
            name: name.toUpperCase(),
            role: 'admin',
            isActive: true,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
    } catch (error) {
        throw new Error('Falha ao criar usuário, tente novamente');
    }
};

export const logout = () => auth.signOut();

export const getUserInfo = async (currentUserUID) => {
    const doc = await firebase
        .firestore()
        .collection('users')
        .doc(currentUserUID)
        .get();

    if (!doc.exists) {
        throw new Error('Usuário não existe');
    }
    return doc.data();
};

export const passwordReset = (email) => auth.sendPasswordResetEmail(email);

export const getDashBoardData = async () => {
    let partners = 0;
    let analise = 0;
    let pendente = 0;
    let aprovado = 0;
    let cancelado = 0;
    let unpaidValue = 0;

    // get all Partners
    await db
        .collection('users')
        .where('role', '!=', 'admin')
        .get()
        .then((querySnapshot) => {
            partners = querySnapshot.size;
        });

    // get indicações Pendente
    await db
        .collection('indications')
        .where('status', '==', 'Pendente')
        .get()
        .then((querySnapshot) => {
            pendente = querySnapshot.size;
        });

    // get indicações em Análise
    await db
        .collection('indications')
        .where('status', '==', 'Análise')
        .get()
        .then((querySnapshot) => {
            analise = querySnapshot.size;
        });
    // get indicações Aprovadas
    await db
        .collection('indications')
        .where('status', '==', 'Aprovado')
        .get()
        .then((querySnapshot) => {
            aprovado = querySnapshot.size;
        });
    // get indicações Canceladas
    await db
        .collection('indications')
        .where('status', '==', 'Cancelado')
        .get()
        .then((querySnapshot) => {
            cancelado = querySnapshot.size;
        });

    // get approved and unpaid indications
    await db
        .collection('users')
        .where('role', '!=', 'admin')
        .get()
        .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                const { currentBalance } = doc.data();
                unpaidValue += currentBalance || 0;
            });
        });

    return { partners, analise, pendente, aprovado, cancelado, unpaidValue };
};

export const getPartner = async (id) => {
    let allPaidIndications = 0;
    const doc = await db.collection('users').doc(id).get();

    if (!doc.exists) {
        return {};
    }

    try {
        await db
            .collection('indications')
            .where('userId', '==', id)
            .where('isPaid', '==', true)
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc2) => {
                    // doc.data() is never undefined for query doc snapshots
                    const { bonusValue } = doc2.data();
                    allPaidIndications += bonusValue || 0;
                });
            });
    } catch {
        throw new Error('Falha na soma das indicações pagas!');
    }

    const partner = {
        ...doc.data(),
        allPaidIndications,
    };

    return partner;
};

export const setDisablePartner = async (userID) => {
    await db.collection('users').doc(userID).update({
        isActive: false,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
};

export const setActivePartner = async (userID) => {
    await db.collection('users').doc(userID).update({
        isActive: true,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
};

export const getPartnerIndications = async (userID, status) => {
    const docs = [];

    const indicationsRef = await db
        .collection('indications')
        .where('userId', '==', userID);

    await indicationsRef
        .where('status', '==', status)
        .orderBy('createdAt', 'desc')
        .get()
        .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                docs.push({
                    id: doc.id,
                    ...doc.data(),
                });
            });
        });

    return docs;
};

export const updateIndicationStatus = async (
    userID,
    indicationID,
    oldStatus,
    status,
    bonusValue,
    messages
) => {
    // Criar Document de historico de alteração

    const user = await db.collection('users').doc(userID).get();
    const userNotifications = user.data().notifications || [];

    try {
        await db.collection('indications_history').add({
            userId: userID,
            indicationId: indicationID,
            oldStatus,
            status,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
    } catch (error) {
        throw new Error(error);
    }

    try {
        // Atualizar indicação
        await db
            .collection('indications')
            .doc(indicationID)
            .update({
                status,
                bonusValue: parseFloat(bonusValue),
                messages,
                updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
            });
    } catch (error) {
        throw new Error(error);
    }

    if (status === 'Aprovado') {
        // Pegar Valor de comissão do usuário para incrementar!
        const { currentBalance } = user.data();
        const newBallance = parseFloat(currentBalance) + parseFloat(bonusValue);

        const newNotification = {
            id: indicationID,
            type: 'aprovado',
            message: `Uma indicação foi aprovada, você recebeu um crédito de ${formatMoney(
                bonusValue
            )}`,
            createdAt: new Date(),
        };

        const notifications = [newNotification, ...userNotifications];

        try {
            await db.collection('users').doc(userID).update({
                notifications,
                currentBalance: newBallance,
                updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
            });
        } catch (error) {
            throw new Error(error);
        }

        // Criar Document para histórico de saques e débitos
        try {
            await db.collection('users_activities').add({
                userId: userID,
                indicationId: indicationID,
                type: 'deposit',
                value: parseFloat(bonusValue),
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
            });
        } catch (error) {
            throw new Error(error);
        }
    } else {
        // Gerar Notificação quando uma indicação
        // receber atualizações

        const newNotification = {
            id: indicationID,
            type: status === 'atualizado',
            message: `Uma indicação recebeu atualização, situação atual: ${status}`,
            createdAt: new Date(),
        };

        const notifications = [newNotification, ...userNotifications];

        try {
            await db.collection('users').doc(userID).update({
                notifications,
                updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
            });
        } catch (error) {
            throw new Error(error);
        }
    }
};

export const getIndicationHistory = async (indicationId) => {
    const allIndicationsHistory = [];

    const indicationHistoryRef = db
        .collection('indications_history')
        .where('indicationId', '==', indicationId);

    await indicationHistoryRef
        .orderBy('createdAt', 'asc')
        .get()
        .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                allIndicationsHistory.push({ id: doc.id, ...doc.data() });
            });
        });

    return allIndicationsHistory;
};

// Get all Partners paginated
export const getPartners = async (limit = 10, searchName = '') => {
    let total = 0;
    let hasMore = false;
    const data = [];
    let lastVisible = {};

    if (searchName.length >= 3) {
        if (hasNumber(searchName)) {
            const formattedCpf = formatCpf(searchName);

            const partnerCpfRef = db
                .collection('users')
                .where('cpf', '>=', formattedCpf)
                .where('cpf', '<=', `${formattedCpf}\uf8ff`)
                .orderBy('cpf', 'desc');

            await partnerCpfRef
                .limit(limit)
                .get()
                .then((querySnapshot) => {
                    total = querySnapshot.size;
                    hasMore = total >= limit || total < 0;
                    lastVisible =
                        querySnapshot.docs.length > 0 &&
                        querySnapshot.docs[
                            querySnapshot.docs.length - 1
                        ].data();

                    querySnapshot.forEach((doc) => {
                        const partner = doc.data();
                        // doc.data() is never undefined for query doc snapshots
                        data.push({
                            id: doc.id,
                            ...partner,
                        });
                    });
                });
        } else {
            const partnerNameRef = db
                .collection('users')
                .where('name', '>=', searchName.toUpperCase())
                .where('name', '<=', `${searchName.toUpperCase()}\uf8ff`)
                .orderBy('name', 'desc');

            await partnerNameRef
                .limit(limit)
                .get()
                .then((querySnapshot) => {
                    total = querySnapshot.size;
                    hasMore = total >= limit || total < 0;
                    lastVisible =
                        querySnapshot.docs.length > 0 &&
                        querySnapshot.docs[
                            querySnapshot.docs.length - 1
                        ].data();

                    querySnapshot.forEach((doc) => {
                        const partner = doc.data();
                        // doc.data() is never undefined for query doc snapshots
                        data.push({
                            id: doc.id,
                            ...partner,
                        });
                    });
                });
        }
    } else {
        await db
            .collection('users')
            /* .where('role', '!=', 'admin')
            .orderBy('role', 'asc') */
            .orderBy('createdAt', 'desc')
            .limit(limit)
            .get()
            .then((querySnapshot) => {
                total = querySnapshot.size;
                hasMore = total >= limit || total < 0;
                lastVisible =
                    querySnapshot.docs.length > 0 &&
                    querySnapshot.docs[querySnapshot.docs.length - 1].data();

                querySnapshot.forEach((doc) => {
                    const partner = doc.data();
                    // doc.data() is never undefined for query doc snapshots
                    data.push({
                        id: doc.id,
                        ...partner,
                    });
                });
            });
    }

    return {
        total,
        data,
        hasMore,
        lastVisible,
    };
};

export const getPartnersNextPage = async (last, limit = 10) => {
    let total = 0;
    let hasMore = false;
    const data = [];
    let lastVisible = {};

    await db
        .collection('users')
        /* .where('role', '!=', 'admin') */
        /*  .orderBy('role', 'asc') */
        .orderBy('createdAt', 'desc')
        .startAfter(last.createdAt)
        .limit(limit)
        .get()
        .then((querySnapshot) => {
            total = querySnapshot.size;
            hasMore = total >= limit || total < 0;
            lastVisible =
                querySnapshot.docs.length > 0 &&
                querySnapshot.docs[querySnapshot.docs.length - 1].data();

            querySnapshot.forEach((doc) => {
                const partner = doc.data();
                // doc.data() is never undefined for query doc snapshots
                data.push({
                    ...partner,
                    id: partner.id,
                });
            });
        });

    return { total, data, hasMore, lastVisible };
};

// Get All Indications paginated
export const getIndications = async (
    status = 'Pendente',
    sort = 'desc',
    limit = 10
) => {
    let total = 0;
    let hasMore = false;
    const data = [];

    const IndicationsRef = db
        .collection('indications')
        .where('status', '==', status);

    // get all Partners
    await IndicationsRef.orderBy('createdAt', sort)
        .limit(limit)
        .get()
        .then((querySnapshot) => {
            total = querySnapshot.size;
            hasMore = total >= limit || total < 0;
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                data.push({
                    id: doc.id,
                    ...doc.data(),
                });
            });
        });

    return {
        total,
        data,
        hasMore,
    };
};

export const getIndicationBySearch = async (search = '') => {
    let total = 0;
    const data = [];

    if (!hasNumber(search)) {
        const toUpperCaseSearch = search.toUpperCase();

        await db
            .collection('indications')
            .where('name', '>=', toUpperCaseSearch)
            .where('name', '<=', `${toUpperCaseSearch}\uf8ff`)
            .orderBy('name', 'desc')
            .get()
            .then((querySnapshot) => {
                total = querySnapshot.size;
                querySnapshot.forEach((doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    data.push({
                        id: doc.id,
                        ...doc.data(),
                    });
                });
            });
    } else {
        const formattedCpf = formatCpf(search);

        await db
            .collection('indications')
            .where('cpf', '>=', formattedCpf)
            .where('cpf', '<=', `${formattedCpf}\uf8ff`)
            .orderBy('cpf', 'desc')
            .get()
            .then((querySnapshot) => {
                total = querySnapshot.size;
                querySnapshot.forEach((doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    data.push({
                        id: doc.id,
                        ...doc.data(),
                    });
                });
            });
    }

    const filterData = data.sort((a, b) =>
        b.createdAt > a.createdAt ? 1 : -1
    );
    /*  .filter((indication) => !['Cancelado'].includes(indication.status)) */

    return {
        total,
        data: filterData,
    };
};

export const getIndicationsNextPage = async (
    last,
    status = 'Pendente',
    sort = 'desc',
    limit = 10
) => {
    let total = 0;
    let hasMore = false;
    const data = [];

    const IndicationsRef = db
        .collection('indications')
        .where('status', '==', status);

    await IndicationsRef.orderBy('createdAt', sort)
        .startAfter(last.createdAt)
        .limit(limit)
        .get()
        .then((querySnapshot) => {
            total = querySnapshot.size;
            hasMore = total >= limit || total < 0;
            querySnapshot.forEach((doc) => {
                const indication = doc.data();
                // doc.data() is never undefined for query doc snapshots
                data.push({
                    id: doc.id,
                    ...indication,
                });
            });
        });

    return { total, data, hasMore };
};

export const getUnpaidIndications = async (limit = 10) => {
    let total = 0;
    let hasMore = false;
    const data = [];

    const indicationsRef = db
        .collection('indications')
        .where('status', '==', 'Aprovado');

    const unpaidIndicationRef = indicationsRef.where('isPaid', '==', false);

    // get all Partners
    await unpaidIndicationRef
        .orderBy('createdAt', 'desc')
        .limit(limit)
        .get()
        .then((querySnapshot) => {
            total = querySnapshot.size;
            hasMore = total >= limit || total < 0;
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                data.push({
                    id: doc.id,
                    ...doc.data(),
                });
            });
        });

    return {
        total,
        data,
        hasMore,
    };
};

export const getUnpaidIndicationsNextPage = async (last, limit = 10) => {
    let total = 0;
    let hasMore = false;
    const data = [];

    const indicationsRef = db
        .collection('indications')
        .where('status', '==', 'Aprovado');

    const unpaidIndicationRef = indicationsRef.where('isPaid', '==', false);

    // get all Partners
    await unpaidIndicationRef
        .orderBy('createdAt', 'desc')
        .startAfter(last.createdAt)
        .limit(limit)
        .get()
        .then((querySnapshot) => {
            total = querySnapshot.size;
            hasMore = total >= limit || total < 0;
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                data.push({
                    id: doc.id,
                    ...doc.data(),
                });
            });
        });

    return {
        total,
        data,
        hasMore,
    };
};

// Pay Indication
export const payIndication = async (userID, indicationID) => {
    // Pegar bonusValue da indicação
    // Alterar status da indicação para isPaid: true
    const indication = await db
        .collection('indications')
        .doc(indicationID)
        .get();

    const { bonusValue } = indication.data();

    try {
        db.collection('indications').doc(indicationID).update({
            isPaid: true,
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
    } catch (error) {
        throw new Error(error);
    }

    // Gerar saque no extrato do usuário
    // Criar Document para histórico de saques e débitos
    try {
        await db.collection('users_activities').add({
            userId: userID,
            indicationId: indicationID,
            type: 'withdraw',
            value: parseFloat(bonusValue),
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
    } catch (error) {
        throw new Error(error);
    }

    // Pegar currenBalance do usuario
    // Alterar(debitar) o bonusValue do currentBalance do user
    const user = await db.collection('users').doc(userID).get();
    const { currentBalance } = user.data();
    const newBallance = parseFloat(currentBalance) - parseFloat(bonusValue);

    try {
        await db.collection('users').doc(userID).update({
            currentBalance: newBallance,
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
    } catch (error) {
        throw new Error(error);
    }
};

// Get All Activities
export const getPartnerActivities = async (userID) => {
    const data = [];

    const activitiesRef = db
        .collection('users_activities')
        .where('userId', '==', userID);

    // get all Partners
    await activitiesRef
        .orderBy('createdAt', 'desc')
        .get()
        .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                const activitie = doc.data();

                const formatedDate = formatDistance(
                    new Date(activitie?.updatedAt?.seconds * 1000).getTime(),
                    new Date().getTime(),
                    { locale: pt, addSuffix: true }
                );

                return data.push({
                    ...activitie,
                    id: doc.id,
                    value: activitie?.value,
                    formatedDate,
                    updatedAt: new Date(
                        activitie?.updatedAt?.seconds * 1000
                    ).toLocaleString('pt-BR'),
                    createdAt: new Date(
                        activitie?.createdAt?.seconds * 1000
                    ).toLocaleString('pt-BR'),
                });
            });
        });

    return data;
};
