import React, { useState, useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import notFoundImage from '@/assets/images/notfound.svg';
import { ReactComponent as EyeSvg } from '@/assets/svgs/eye.svg';
import { ReactComponent as LoadingSvg } from '@/assets/svgs/loading.svg';
import Pagination from '@/components/Pagination';
import TooltipButton from '@/components/TooltipButton';
import { useSearch } from '@/contexts/SearchContext';
import { getPartners, getPartnersNextPage } from '@/services/api';
import history from '@/services/history';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const Partners = () => {
    const { handleClear } = useSearch();
    const query = useQuery();
    const queryName = query.get('name') || '';

    const [loading, setLoading] = useState(null);
    const [partners, setPartners] = useState([]);
    const [page, setPage] = useState(1);
    const [hasMoreData, setHasMoreData] = useState(false);
    const [startAfter, setStartAfter] = useState({});

    const header = ['Nome', 'Email', 'Criado em', 'Ações'];
    const limit = 10;

    async function handleGetPartners() {
        setLoading(true);
        const { data, hasMore, lastVisible } = await getPartners(
            limit,
            queryName
        );
        setPartners(data);
        setHasMoreData(hasMore);
        setStartAfter(lastVisible);
        setPage(1);
        setLoading(false);
    }

    async function handleNextPage() {
        setLoading(true);
        const { data, hasMore, lastVisible } = await getPartnersNextPage(
            startAfter,
            limit
        );
        setPartners([...partners, ...data]);
        setHasMoreData(hasMore);
        setStartAfter(lastVisible);
        setPage((prev) => prev + 1);
        setLoading(false);
    }

    useEffect(() => {
        handleGetPartners();
    }, [queryName]); // eslint-disable-line react-hooks/exhaustive-deps

    if (loading) {
        return (
            <div className="w-full h-screen -mt-16 flex items-center justify-center">
                <LoadingSvg />
            </div>
        );
    }

    return (
        <>
            {/* <!--    <!-- Search  -- --> */}
            {queryName && (
                <div className="mt-6">
                    <h3 className="text-lg text-gray-600">Pesquisou por</h3>
                    <div className="bg-gray-200 p-2 inline-flex items-center  text-gray-500 rounded">
                        <span className="text-sm">{queryName}</span>
                        <button
                            className="w-6 h-6
                         text-center
                        bg-gray-300
                         text-gray-500
                         rounded-full
                         ml-2
                         outline-none
                         focus:outline-none
                         ring-gray-300
                         focus:ring-2
                         hover:opacity-80
                         transition-all
                         duration-150
                         "
                            type="button"
                            onClick={handleClear}
                        >
                            x
                        </button>
                    </div>
                </div>
            )}
            <h2 className="my-6 text-2xl font-semibold text-gray-700">
                Lista de Parceiros
            </h2>
            {/* <!--    <!-- With actions -- --> */}

            <div className="w-full overflow-hidden rounded-lg shadow-xs">
                <div className="w-full overflow-x-auto">
                    <table className="w-full whitespace-no-wrap">
                        <thead>
                            <tr className="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b">
                                {header.map((item) => (
                                    <th key={item} className="px-4 py-3">
                                        {item}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y">
                            {partners.map((partner) => (
                                <tr
                                    key={partner.id}
                                    className={`text-gray-700
                                ${partner.role === 'admin' && 'bg-red-50'}
                                ${
                                    partner.role === 'moderator' &&
                                    'bg-orange-50'
                                }
                                `}
                                >
                                    <td className="px-4 py-3">
                                        <p className="font-semibold capitalize">
                                            {partner.name}
                                        </p>
                                    </td>
                                    <td className="px-4 py-3 text-sm">
                                        {partner.email}
                                    </td>
                                    <td className="px-4 py-3 text-sm">
                                        {new Date(
                                            partner?.createdAt?.seconds * 1000
                                        ).toLocaleString('pt-BR')}
                                    </td>
                                    <td className="px-4 py-3">
                                        <div className="flex items-center space-x-4 text-sm">
                                            <TooltipButton
                                                tip="visualizar"
                                                aria-label="view"
                                                onClick={() =>
                                                    history.push(
                                                        `/view-partners/${partner.id}`
                                                    )
                                                }
                                            >
                                                <EyeSvg />
                                            </TooltipButton>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {!loading && partners?.length <= 0 && (
                        <div className="my-12 flex flex-col w-full items-center justify-center">
                            <img
                                style={{ maxWidth: '250px' }}
                                src={notFoundImage}
                                alt="not found"
                            />
                            <span className="text-gray-500 text-sm mt-2">
                                Não encontramos parceiros
                            </span>
                        </div>
                    )}
                    {partners?.length > 0 && (
                        <Pagination
                            handleNext={handleNextPage}
                            page={page}
                            hasMore={hasMoreData}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default Partners;
