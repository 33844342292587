import React from 'react';

import Button from '@/components/Button';

const Pagination = ({ handleNext, hasMore }) => {
    return (
        <div className="w-full inline-flex items-center justify-center bg-white py-4">
            {hasMore ? (
                <div>
                    <Button
                        aria-label="Next"
                        type="button"
                        onClick={() => handleNext()}
                    >
                        Carregar mais
                    </Button>
                </div>
            ) : (
                <span className="font-medium text-gray-400 text-sm">
                    Não há mais para carregar
                </span>
            )}
        </div>
    );
};

export default Pagination;
