/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useCallback, useState } from 'react';

import { Formik } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import Button from '@/components/Button';
import InputCurrency from '@/components/InputCurrency';
import InputCurrencyUncontrolled from '@/components/InputCurrency/InputCurrencyUncontrolled';
import SelectIndicationStatus from '@/components/Select/IndicationSelect';
import SelectUncontrolled from '@/components/Select/SelectUncontrolled';
import InputTextArea from '@/components/TextArea';
import { useAuth } from '@/contexts/AuthContext';
import { usePartner } from '@/contexts/PartnerContext';
import useCopyToClipboard from '@/hooks/useClipBoard';
import { updateIndicationStatus } from '@/services/api';

const taxes = [
    { key: '1', value: 0.01, label: '1%' },
    { key: '1.5', value: 0.015, label: '1.5%' },
    { key: '2', value: 0.02, label: '2%' },
    { key: '2.5', value: 0.025, label: '2.5%' },
    { key: '3', value: 0.03, label: '3%' },
    { key: '3.5', value: 0.035, label: '3.5%' },
    { key: '4', value: 0.04, label: '4%' },
    { key: '4.5', value: 0.045, label: '4.5%' },
    { key: '5', value: 0.05, label: '5%' },
    { key: '5.5', value: 0.055, label: '5.5%' },
    { key: '6', value: 0.06, label: '6%' },
    { key: '6.5', value: 0.065, label: '6.5%' },
    { key: '7', value: 0.07, label: '7%' },
    { key: '7.5', value: 0.075, label: '7.5%' },
    { key: '8', value: 0.08, label: '8%' },
    { key: '8.5', value: 0.085, label: '8.5%' },
    { key: '9', value: 0.09, label: '9%' },
    { key: '9.5', value: 0.095, label: '9.5%' },
    { key: '10', value: 0.1, label: '10%' },
];

const IndicationStatusModal = ({
    actionLabel = 'Deletar',
    title,
    onClose,
    selectedIndication,
    onConfirm,
}) => {
    const { user } = useAuth();
    const { handlePartnerBonusValue } = usePartner();
    const [selectedIndicationStatus, setSelectedIndicationStatus] = useState(
        selectedIndication.status
    );

    const [selectedTaxes, setSelectedTaxes] = useState(0.01);
    const [indicationValue, setIndicationValue] = useState('');
    const [partnerBonus, setPartnerBonus] = useState(0);
    const [confirmLoading, setConfirmLoading] = useState(false);

    // eslint-disable-next-line no-unused-vars
    const [_, copy] = useCopyToClipboard('');

    const indicationStatusArrayModerator = [
        { id: 'pendente', value: 'Pendente', label: 'Pendente' },
        { id: 'analise', value: 'Análise', label: 'Análise' },
        { id: 'cancelado', value: 'Cancelado', label: 'Cancelado' },
    ];

    const indicationStatusArrayAdmin = [
        { id: 'pendente', value: 'Pendente', label: 'Pendente' },
        { id: 'analise', value: 'Análise', label: 'Análise' },
        { id: 'aprovado', value: 'Aprovado', label: 'Aprovado' },
        { id: 'cancelado', value: 'Cancelado', label: 'Cancelado' },
    ];

    const indicationStatusArray =
        user.role === 'admin'
            ? indicationStatusArrayAdmin
            : indicationStatusArrayModerator;

    function handleClose() {
        onClose(false);
    }

    const downHandler = useCallback(
        (event) => {
            if (event.keyCode === 27) {
                onClose();
            }
        },
        [onClose]
    );

    function handleComissao() {
        if (indicationValue === '') {
            return;
        }

        const indicationValueFormatted = Number(
            indicationValue.replace('.', '')
        );

        const bonus = Math.round(indicationValueFormatted * selectedTaxes);

        setPartnerBonus(bonus);
    }

    function handleClipBoard() {
        copy(String(partnerBonus));
    }

    async function handleConfirmChange(values) {
        setConfirmLoading(true);
        const { selectedStatus, bonusValue, obs } = values;

        let finalBonusValue = 0;
        if (selectedStatus === 'Aprovado') {
            finalBonusValue = parseFloat(bonusValue);
        }

        const {
            userId: userID,
            id: IndicationID,
            status: oldStatus,
            messages: oldMessages = [],
        } = selectedIndication;

        let newMessages = [...oldMessages];

        if (obs.length > 0) {
            const newObs = {
                id: new Date(),
                agentName: user.firstName || 'Atendente',
                type: 'agent',
                message: obs,
            };
            newMessages = [...oldMessages, newObs];
        }

        try {
            await updateIndicationStatus(
                userID,
                IndicationID,
                oldStatus,
                selectedStatus,
                finalBonusValue,
                newMessages
            );

            handlePartnerBonusValue('sum', finalBonusValue);

            toast.success('Indicação atualizado com sucesso');
        } catch (error) {
            toast.error(
                'Erro ao atualizar indicação, tente novamente mais tarde.'
            );
        } finally {
            setConfirmLoading(false);
        }

        onConfirm();
    }

    useEffect(() => {
        document.addEventListener('keydown', downHandler, false);

        return () => {
            document.removeEventListener('keydown', downHandler, false);
        };
    }, [downHandler]);

    useEffect(() => {
        handleComissao();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTaxes]);

    return (
        <div className="fixed z-20 inset-0 overflow-y-auto">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 transition-opacity">
                    <div className="absolute inset-0 bg-gray-500 opacity-75" />
                </div>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" />
                <div
                    className="
                    inline-block
                    align-bottom
                    bg-white
                    rounded-lg
                    text-left
                    overflow-hidden
                    shadow-xl
                    transform
                    transition-all
                    sm:my-8
                    sm:align-middle
                    sm:max-w-lg sm:w-full
                    "
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline"
                >
                    <header className="flex flex-row items-center justify-between py-2 px-4 bg-primary">
                        <h3
                            className="text-xl leading-6 font-medium text-white"
                            id="modal-headline"
                        >
                            {title}
                        </h3>
                        <button
                            type="button"
                            onClick={handleClose}
                            className="
                            flex items-center justify-center
                            h-8 w-8 bg-transparent
                            text-gray-600
                            bg-gray-100
                            rounded-lg
                            hover:opacity-80
                            transition duration-200 ease-in-out
                            outline-none focus:outline-none
                            "
                            style={{ top: '10px', right: '10px' }}
                        >
                            x
                        </button>
                    </header>
                    <div className="bg-white px-4 pb-4 sm:p-6 sm:pb-4">
                        <div className="w-full overflow-auto h-4/6">
                            {/** MODAL BODY */}
                            <div className="flex-row justify-between md:flex flex-wrap mb-2">
                                <div className="md:mt-0 mt-4">
                                    <span className="text-xs text-gray-600 uppercase">
                                        Parceiro:
                                    </span>
                                    <p className="text-gray-700 text-sm font-medium">
                                        {selectedIndication.partnerName}
                                    </p>
                                </div>
                                <div className="md:mt-0 mt-2">
                                    <span className="text-xs text-gray-600 uppercase">
                                        Indicado:
                                    </span>
                                    <p className="text-gray-700 text-sm font-medium">
                                        {selectedIndication.name}
                                    </p>
                                </div>
                            </div>
                            <div className="block">
                                <span className="text-xs text-gray-600 uppercase">
                                    Situação atual:
                                </span>
                                <p className="text-gray-700 text-sm font-medium uppercase">
                                    {selectedIndication.status}
                                </p>
                            </div>
                            <Formik
                                enableReinitialize
                                initialValues={{
                                    selectedStatus: selectedIndication.status,
                                    bonusValue: '',
                                    obs: '',
                                }}
                                validationSchema={Yup.object().shape({
                                    bonusValue: Yup.string().when(
                                        'selectedStatus',
                                        {
                                            is: (value) =>
                                                value && value === 'Aprovado',
                                            then: Yup.string().when(
                                                'bonusValue',
                                                (bonusValue, field) =>
                                                    !bonusValue
                                                        ? field.required(
                                                              'Digite a comissão'
                                                          )
                                                        : field
                                            ),
                                            otherwise: Yup.string(),
                                        }
                                    ),
                                    obs: Yup.string().max(
                                        253,
                                        'Observação de até 253 caracteres'
                                    ),
                                })}
                                onSubmit={(values) => {
                                    handleConfirmChange(values);
                                }}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleBlur,
                                    handleSubmit,
                                    setFieldValue,
                                    handleChange,
                                }) => (
                                    <form
                                        id="form-indication"
                                        onSubmit={handleSubmit}
                                        noValidate
                                    >
                                        <SelectIndicationStatus
                                            name="selectedStatus"
                                            label="Selecionar Situação"
                                            value={values.selectedStatus}
                                            options={indicationStatusArray}
                                            onChange={setFieldValue}
                                            onBlur={handleBlur}
                                            helperText={
                                                touched.selectedStatus
                                                    ? errors.selectedStatus
                                                    : ''
                                            }
                                            error={
                                                touched.selectedStatus &&
                                                Boolean(errors.selectedStatus)
                                            }
                                            selectionState={
                                                setSelectedIndicationStatus
                                            }
                                            isFormik
                                        />

                                        {/*  CALCULO DE COMISSAO */}
                                        {selectedIndicationStatus ===
                                            'Aprovado' && (
                                            <>
                                                <hr className="my-2" />
                                                <div className="w-full flex flex-row space-x-2">
                                                    <SelectUncontrolled
                                                        label="Selecionar Taxa"
                                                        options={taxes}
                                                        value={selectedTaxes}
                                                        onChange={
                                                            setSelectedTaxes
                                                        }
                                                    />
                                                    <InputCurrencyUncontrolled
                                                        label="Valor contrato"
                                                        placeholder="Valor contrato"
                                                        name="indicationValue"
                                                        value={indicationValue}
                                                        onChange={
                                                            setIndicationValue
                                                        }
                                                        onBlur={handleComissao}
                                                    />
                                                    <div className="self-end">
                                                        <label className="uppercase text-gray-700 text-xs font-bold mt-4 mb-1">
                                                            Comissão
                                                        </label>
                                                        <button
                                                            type="button"
                                                            className="
                                                            w-28 p-2 mb-1
                                                            flex flex-col justify-around items-center
                                                            bg-primary
                                                            rounded
                                                            outline-none
                                                            hover:bg-primaryHover
                                                            focus:outline-none
                                                            focus:ring-2
                                                            focus:ring-orange-500
                                                            transition-all
                                                            duration-150"
                                                            onClick={
                                                                handleClipBoard
                                                            }
                                                        >
                                                            <strong className="text-lg text-white">
                                                                {partnerBonus.toLocaleString(
                                                                    'pt-BR',
                                                                    {
                                                                        style: 'currency',
                                                                        currency:
                                                                            'BRL',
                                                                    }
                                                                )}
                                                            </strong>
                                                        </button>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        {/*  CALCULO DE COMISSAO */}

                                        {selectedIndicationStatus ===
                                            'Aprovado' && (
                                            <>
                                                <hr className="mt-2" />
                                                <InputCurrency
                                                    name="bonusValue"
                                                    label="Digite o valor da comissão"
                                                    placeholder="Valor comissão ex R$ 100"
                                                    onChange={(e) => {
                                                        setFieldValue(
                                                            'bonusValue',
                                                            e.target.value
                                                        );
                                                    }}
                                                    value={values.bonusValue}
                                                    error={
                                                        touched.bonusValue &&
                                                        Boolean(
                                                            errors.bonusValue
                                                        )
                                                    }
                                                    helperText={
                                                        touched.bonusValue
                                                            ? errors.bonusValue
                                                            : ''
                                                    }
                                                />
                                                <hr className="my-2" />
                                            </>
                                        )}

                                        <InputTextArea
                                            name="obs"
                                            label="Observação"
                                            placeholder="Digite uma observação"
                                            value={values.obs}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={
                                                touched.obs ? errors.obs : ''
                                            }
                                            error={
                                                touched.obs &&
                                                Boolean(errors.obs)
                                            }
                                        />
                                    </form>
                                )}
                            </Formik>
                        </div>
                        <div className="bg-white px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                            <span className="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto space-x-2">
                                <Button
                                    variant="secondary"
                                    onClick={handleClose}
                                >
                                    Cancelar
                                </Button>
                                <Button
                                    form="form-indication"
                                    type="submit"
                                    variant="success"
                                    disabled={confirmLoading}
                                    loading={confirmLoading}
                                >
                                    {actionLabel}
                                </Button>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IndicationStatusModal;
