import React from 'react';

import numeral from 'numeral';
import 'numeral/locales';

const UINumber = ({ format, customStyle = '', children }) => {
    numeral.locale('pt-BR');

    return (
        <span
            className={`
        ${customStyle || 'text-2xl text-gray-600 font-semibold'}
        `}
        >
            {numeral(children).format(format)}
        </span>
    );
};

export default UINumber;
