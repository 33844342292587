import React, { useState, useEffect } from 'react';

import { toast } from 'react-toastify';

import notFoundImage from '@/assets/images/notfound.svg';
import { ReactComponent as EditSvg } from '@/assets/svgs/edit.svg';
import { ReactComponent as EyeSvg } from '@/assets/svgs/eye.svg';
import { ReactComponent as HistorySvg } from '@/assets/svgs/history.svg';
import { ReactComponent as LoadingSvg } from '@/assets/svgs/loading.svg';
import { ReactComponent as PaySvg } from '@/assets/svgs/pay.svg';
import IndicationHistoryModal from '@/components/Modals/IndicationHistoryModal';
import IndicationStatusModal from '@/components/Modals/IndicationStatusModal';
import IndicationViewModal from '@/components/Modals/IndicationViewModal';
import { useAuth } from '@/contexts/AuthContext';
import { usePartner } from '@/contexts/PartnerContext';
import formatMoney from '@/utils/formatMoney';

import ConfirmModal from '../Modals/ConfirmModal';
import TooltipButton from '../TooltipButton';

const TablePartners = () => {
    const { isAdmin } = useAuth();

    const {
        loadingIndications,
        indications,
        tableIsActive,
        handleGetIndications,
        handlePayIndication,
    } = usePartner();

    const [selectedIndication, setSelectedIndication] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState('Pendente');

    const [indicationViewModal, setIndicationViewModal] = useState(false);
    const [indicationModal, setIndicationModal] = useState(false);
    const [indicationHistoryModal, setIndicationHistoryModal] = useState(false);
    const [indicationConfirmModal, setIndicationConfirmPayModal] =
        useState(false);

    const header = isAdmin
        ? [
              'Nome/CPF',
              'Telefone',
              'Status',
              'Criado em',
              'Atualizado em',
              'Comissão',
          ]
        : ['Nome/CPF', 'Telefone', 'Status', 'Criado em', 'Atualizado em'];

    useEffect(() => {
        handleGetIndications('Pendente');
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    function handleSelectedIndication(indication) {
        setSelectedIndication(indication);
        setIndicationModal(true);
    }

    function handleCloseIndicationModal() {
        setIndicationModal(false);
        setSelectedIndication(null);
    }

    function handleOpenIndicationHistoryModal(indication) {
        setSelectedIndication(indication);
        setIndicationHistoryModal(true);
    }

    function handleCloseIndicationHistoryModal() {
        setSelectedIndication(null);
        setIndicationHistoryModal(false);
    }

    function handleOpenIndicationViewModal(indication) {
        setSelectedIndication(indication);
        setIndicationViewModal(true);
    }

    function handleCloseIndicationViewModal() {
        setSelectedIndication(null);
        setIndicationViewModal(false);
    }

    function handleConfirmStatusChange() {
        setIndicationModal(false);
        setSelectedIndication(null);
        handleGetIndications('Pendente');
        setSelectedStatus('Pendente');
    }

    function handleChangeStatusIndications(status) {
        setSelectedStatus(status);
        handleGetIndications(status);
    }

    async function handleConfirmIncidationPayment() {
        const indicationId = selectedIndication.id;
        const { userId, bonusValue } = selectedIndication;

        try {
            await handlePayIndication(userId, indicationId, bonusValue);
            toast.success('Indicação paga com sucesso!');
        } catch (error) {
            toast.error(error);
        } finally {
            setSelectedIndication(null);
            setIndicationConfirmPayModal(false);
        }
    }

    function handleOpenConfirmPaymentModal(indication) {
        setSelectedIndication(indication);
        setIndicationConfirmPayModal(true);
    }

    function handleCloseConfirmPaymentModal() {
        setSelectedIndication(null);
        setIndicationConfirmPayModal(false);
    }

    return (
        <div className="w-full max-w-xs md:max-w-full">
            <div className="overflow-x-scroll flex justify-between space-x-1 bg-gray-50 mt-4">
                <button
                    className={`btn flex-1 ${
                        tableIsActive[0].state ? 'bg-orange-500' : 'bg-gray-400'
                    }`}
                    type="button"
                    onClick={() => handleChangeStatusIndications('Pendente')}
                >
                    Pendente
                </button>
                <button
                    className={`btn flex-1 ${
                        tableIsActive[1].state ? 'bg-orange-500' : 'bg-gray-400'
                    }`}
                    type="button"
                    onClick={() => handleChangeStatusIndications('Análise')}
                >
                    Análise
                </button>
                <button
                    className={`btn flex-1 ${
                        tableIsActive[2].state ? 'bg-orange-500' : 'bg-gray-400'
                    }`}
                    type="button"
                    onClick={() => handleChangeStatusIndications('Aprovado')}
                >
                    Aprovado
                </button>
                <button
                    className={`btn flex-1 ${
                        tableIsActive[3].state ? 'bg-orange-500' : 'bg-gray-400'
                    }`}
                    type="button"
                    onClick={() => handleChangeStatusIndications('Cancelado')}
                >
                    Cancelado
                </button>
            </div>

            <div
                className="mt-2 w-full overflow-hidden rounded-lg shadow-xs"
                style={{ minHeight: '300px' }}
            >
                <div className="w-full overflow-x-auto">
                    <table className="w-full whitespace-no-wrap">
                        <thead>
                            <tr className="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b">
                                {header.map((item) => (
                                    <th key={item} className="px-4 py-3">
                                        {item}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y">
                            {indications?.map((indication) => (
                                <tr
                                    key={indication.id}
                                    className={`text-gray-700
                                        ${
                                            indication.isPaid
                                                ? 'bg-lime-200'
                                                : 'bg-transparent'
                                        }
                                    `}
                                >
                                    <td className="px-4 py-3">
                                        <p className="font-semibold capitalize text-sm">
                                            {indication.name}
                                        </p>
                                        <span className="text-gray-500 text-xs">
                                            {indication.cpf}
                                        </span>
                                    </td>
                                    <td className="px-4 py-3 text-xs">
                                        {indication.phone}
                                    </td>
                                    <td className="px-4 py-3 text-xs">
                                        {indication.status}
                                    </td>

                                    <td className="px-4 py-3 text-xs">
                                        {indication
                                            ? new Date(
                                                  indication.createdAt.seconds *
                                                      1000
                                              ).toLocaleDateString('pt-BR')
                                            : null}
                                    </td>
                                    <td className="px-4 py-3 text-xs">
                                        {indication
                                            ? new Date(
                                                  indication.updatedAt.seconds *
                                                      1000
                                              ).toLocaleDateString('pt-BR')
                                            : null}
                                    </td>
                                    {isAdmin && (
                                        <td className="px-4 py-3 text-xs">
                                            {formatMoney(indication.bonusValue)}
                                        </td>
                                    )}
                                    <td className="px-4 py-3">
                                        <div className="flex items-center space-x-4 text-xs">
                                            <TooltipButton
                                                tip="visualizar"
                                                aria-label="view"
                                                onClick={() =>
                                                    handleOpenIndicationViewModal(
                                                        indication
                                                    )
                                                }
                                            >
                                                <EyeSvg />
                                            </TooltipButton>
                                            <TooltipButton
                                                tip="histórico"
                                                aria-label="history"
                                                onClick={() =>
                                                    handleOpenIndicationHistoryModal(
                                                        indication
                                                    )
                                                }
                                            >
                                                <HistorySvg />
                                            </TooltipButton>

                                            {selectedStatus !== 'Aprovado' && (
                                                <TooltipButton
                                                    tip="editar"
                                                    aria-label="edit"
                                                    onClick={() =>
                                                        handleSelectedIndication(
                                                            indication
                                                        )
                                                    }
                                                >
                                                    <EditSvg />
                                                </TooltipButton>
                                            )}

                                            {indication.status === 'Aprovado' &&
                                                indication.isPaid === false &&
                                                isAdmin && (
                                                    <TooltipButton
                                                        tip="pagar"
                                                        aria-label="pay"
                                                        onClick={() =>
                                                            handleOpenConfirmPaymentModal(
                                                                indication
                                                            )
                                                        }
                                                    >
                                                        <PaySvg />
                                                    </TooltipButton>
                                                )}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {!loadingIndications && indications?.length <= 0 && (
                        <div className="my-16 flex flex-col w-full items-center justify-center">
                            <img
                                style={{ maxWidth: '200px' }}
                                src={notFoundImage}
                                alt="not found"
                            />
                            <span className="text-gray-500 text-sm mt-2">
                                Não encontramos indicações
                            </span>
                        </div>
                    )}
                </div>
                {loadingIndications && (
                    <div className="mt-4 w-full self-center flex items-center justify-center">
                        <LoadingSvg />
                    </div>
                )}
            </div>
            {indicationModal && (
                <IndicationStatusModal
                    onClose={() => handleCloseIndicationModal()}
                    title="Alterar Indicação"
                    actionLabel="Confirmar"
                    selectedIndication={selectedIndication}
                    onConfirm={handleConfirmStatusChange}
                />
            )}
            {indicationHistoryModal && (
                <IndicationHistoryModal
                    onClose={() => handleCloseIndicationHistoryModal()}
                    indication={selectedIndication}
                />
            )}
            {indicationViewModal && (
                <IndicationViewModal
                    onClose={() => handleCloseIndicationViewModal()}
                    indication={selectedIndication}
                />
            )}

            {indicationConfirmModal && (
                <ConfirmModal
                    type="danger"
                    actionLabel="Confirmar"
                    title="Confirmar Pagamento"
                    description="Deseja confirmar pagarmento de indicação?"
                    onClose={() => handleCloseConfirmPaymentModal()}
                    onConfirm={() => handleConfirmIncidationPayment()}
                />
            )}
        </div>
    );
};

export default TablePartners;
