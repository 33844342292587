import React from 'react';

import Header from '@/components/Header';
import Navbar from '@/components/NavBar';

export default function DefaultLayout({ children }) {
    return (
        <div className="flex h-screen bg-gray-50">
            <Navbar />
            <main className="h-full w-full overflow-y-auto">
                <Header />
                <div className="container px-6 mx-auto grid">{children}</div>
            </main>
        </div>
    );
}
