import React, { useContext, useState, useEffect, createContext } from 'react';

import { auth, getUserInfo } from '@/services/api';

const AuthContext = createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);

    useEffect(() => {
        // onAuthStateChanged returns an unsubscriber
        const unsubscribeAuth = auth.onAuthStateChanged(async (authUser) => {
            try {
                if (authUser) {
                    const getUser = await getUserInfo(authUser.uid);
                    setUser(getUser);
                    setLoading(false);
                } else {
                    setUser(null);
                    setLoading(false);
                }
            } catch (error) {
                setLoading(false);
            }
        });

        // unsubscribe auth listener on unmount
        return unsubscribeAuth;
    }, []);

    const value = {
        initialLoading: loading,
        signed: !!user,
        user,
        isAdmin: user?.role === 'admin',
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
}
