import React from 'react';

import { Switch } from 'react-router-dom';

import Dashboard from '@/pages/Dashboard';
import ForgotPassword from '@/pages/ForgotPassword';
import Indications from '@/pages/Indications/index';
import NotFound from '@/pages/NotFound';
import Partners from '@/pages/Partners/index';
import ViewPartner from '@/pages/Partners/ViewPartner';
import SignIn from '@/pages/SignIn';
import TermsOfUse from '@/pages/TermsOfUse';
import UnpaidIndications from '@/pages/UnpaidIndications/index';
/* import SignUp from '@/pages/SignUp'; */

import Route from './Route';

const isAdmin = ['admin'];
const isModerator = ['admin', 'moderator'];

export default function Routes() {
    return (
        <Switch>
            <Route path="/" exact component={SignIn} />
            {/* <Route path="/signup" component={SignUp} /> */}
            <Route path="/forgotpassword" component={ForgotPassword} />
            <Route
                path="/dashboard"
                component={Dashboard}
                roles={isModerator}
                isPrivate
            />
            <Route
                path="/partners"
                component={Partners}
                roles={isModerator}
                isPrivate
            />

            <Route
                path="/view-partners/:id"
                component={ViewPartner}
                roles={isModerator}
                isPrivate
            />
            <Route
                path="/indications"
                component={Indications}
                roles={isModerator}
                isPrivate
            />
            <Route
                path="/indications-unpaid"
                component={UnpaidIndications}
                roles={isAdmin}
                isPrivate
            />
            <Route path="/termos-de-uso" component={TermsOfUse} />
            <Route path="/*" component={NotFound} isPrivate />
        </Switch>
    );
}
