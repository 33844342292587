import React from 'react';

import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

const defaultMaskOptions = {
    prefix: '',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: '.',
    allowDecimal: false,
    requireDecimal: false,
    decimalSymbol: ',',
    decimalLimit: 0, // how many digits allowed after the decimal
    integerLimit: 5, // limit length of integer numbers
    allowNegative: false,
    allowLeadingZeroes: false,
};

const InputCurrencyUncontrolled = ({
    placeholder,
    name,
    value,
    label,
    error,
    helperText,
    onBlur,
    onChange,
    type,
    inputRef,
    maskOptions,
    ...inputProps
}) => {
    const currencyMask = createNumberMask({
        ...defaultMaskOptions,
        ...maskOptions,
    });

    return (
        <div className="relative w-full mb-1">
            <label
                htmlFor={name}
                className="block uppercase text-gray-700 text-xs font-bold mt-4 mb-1"
            >
                {label}
            </label>
            <div
                className="
            absolute
            top-12
            inset-y-4
            pointer-events-none
            flex justify-center items-center pl-2 text-gray-70"
            >
                <span className="text-gray-600 text-sm font-medium">R$</span>
            </div>
            <MaskedInput
                mask={currencyMask}
                {...inputProps}
                className={`
                w-full
                py-3
                pl-8
                placeholder-gray-300
                rounded
                text-sm
                shadow
                border
                focus:outline-none
                focus:ring-1
                focus:ring-primaryHover
                focus:border-primaryHover
                leading-tight
                ${error ? 'border-red-400' : 'border-gray-400'}
                `}
                name={name}
                type={type || 'text'}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                onBlur={onBlur}
                placeholder={placeholder || ''}
                style={{
                    transition: 'all 0.15s ease 0s',
                }}
            />
        </div>
    );
};

export default InputCurrencyUncontrolled;
