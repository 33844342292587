import React, { useRef } from 'react';

import { ReactComponent as MenuSvg } from '@/assets/svgs/menu.svg';
import { ReactComponent as SearchSvg } from '@/assets/svgs/search.svg';
import NavBarMobile from '@/components/NavBar/mobile';
import Profile from '@/components/Profile';
import { useSearch } from '@/contexts/SearchContext';
import { useSidebarDrawer } from '@/contexts/SideBarContext';
import useClickOutside from '@/hooks/useClickOutside';

const Header = () => {
    const {
        handleSearch,
        error,
        search,
        setSearch,
        handleClearField,
        handleEnter,
    } = useSearch();
    const { handleToggleSidebar, isOpenNavBar } = useSidebarDrawer();

    const navRef = useRef(null);
    const buttonRef = useRef(null);

    useClickOutside(navRef, buttonRef, handleToggleSidebar);

    return (
        <div className="flex flex-col flex-1 w-full">
            <header className="z-10 py-4 bg-white shadow-md">
                <div className="grid grid-cols-4 px-6 text-primaryHover">
                    <button
                        ref={buttonRef}
                        className="p-1 h-8 w-8 -ml-1 rounded-md md:hidden focus:outline-none"
                        onClick={handleToggleSidebar}
                        aria-label="Menu"
                        type="button"
                    >
                        <MenuSvg />
                    </button>
                    {/* <!-- Search input --> */}
                    <div className="col-span-4 md:col-span-3 order-3 sm:order-1 flex justify-center flex-1 mt-4 md:mt-0 lg:mr-32">
                        <div className="inline-flex w-full">
                            <div className="relative w-full mr-2 sm:mr-4">
                                <div className="absolute inset-y-0 flex items-center pl-2 text-orange-400">
                                    <SearchSvg />
                                </div>

                                <input
                                    className={`
                                    h-8 w-full pl-8 pr-2
                                    placeholder-gray-400
                                    text-gray-700
                                    rounded
                                    text-sm
                                    shadow
                                    focus:outline-none
                                    focus:ring-0
                                    focus:ring-primaryHover
                                    focus:border-primaryHover
                                    leading-tight
                                    ${error && 'border-2 border-red-400 '}
                                    `}
                                    type="text"
                                    placeholder="Nome ou cpf do parceiro"
                                    aria-label="Search"
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    onKeyDown={(e) => handleEnter(e)}
                                />
                                {search.length > 0 && (
                                    <div
                                        className="absolute
                                        inset-y-0
                                        flex
                                        items-center
                                        right-2
                                        "
                                    >
                                        <button
                                            className="
                                            text-gray-500
                                            text-lg
                                            px-2
                                            rounded-full
                                            outline-none
                                            focus:outline-none
                                            bg-gray-100"
                                            type="button"
                                            onClick={handleClearField}
                                        >
                                            x
                                        </button>
                                    </div>
                                )}
                            </div>
                            <button
                                className="
                                    btn
                                    bg-primary
                                    inline-flex
                                    justify-center
                                    items-center
                                    text-white
                                    text-xs
                                    rounded px-4
                                    "
                                type="button"
                                onClick={handleSearch}
                            >
                                <SearchSvg className="w-4 h-4 mr-1" />
                                <span className="hidden md:block">
                                    Pesquisar
                                </span>
                            </button>
                        </div>
                    </div>
                    <ul className="md:order-2 flex justify-end items-center flex-shrink-0 space-x-6 col-span-3 md:col-span-1">
                        {/*  <Notifications /> */}
                        <Profile />
                    </ul>
                </div>
            </header>
            {isOpenNavBar && <NavBarMobile propRef={navRef} />}
        </div>
    );
};

export default Header;
