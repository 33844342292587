/* eslint-disable no-undef */
import React, { useEffect, useCallback } from 'react';

import UINumber from '@/components/UINumber';

import Bedge from '../Bedge';

const IndicationViewModal = ({ onClose, indication }) => {
    const downHandler = useCallback(
        (event) => {
            if (event.keyCode === 27) {
                onClose();
            }
        },
        [onClose]
    );

    useEffect(() => {
        document.addEventListener('keydown', downHandler, false);

        return () => {
            document.removeEventListener('keydown', downHandler, false);
        };
    }, [downHandler]);

    return (
        <div className="fixed z-20 inset-0 overflow-y-auto">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 transition-opacity">
                    <div className="absolute inset-0 bg-gray-500 opacity-75" />
                </div>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" />
                <div
                    className="inline-block align-bottom bg-white rounded-lg text-left
                    overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle
                    sm:max-w-lg sm:w-full"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline"
                >
                    <header
                        className="
                        flex flex-row items-center justify-between bg-primary py-2 px-4"
                    >
                        <h3
                            className="text-xl leading-6 font-medium text-white"
                            id="modal-headline"
                        >
                            Detalhes da indicação
                        </h3>
                        <button
                            type="button"
                            onClick={onClose}
                            className="
                            flex items-center justify-center
                            h-8 w-8 bg-transparent
                            text-gray-600
                            bg-gray-100
                            rounded-lg
                            hover:opacity-80
                            transition duration-200 ease-in-out
                            outline-none focus:outline-none
                            "
                            style={{ top: '10px', right: '10px' }}
                        >
                            x
                        </button>
                    </header>
                    <div className="bg-white px-4 pb-4 sm:pb-4">
                        <div className="sm:flex sm:items-start">
                            <div className="w-full overflow-auto h-4/6">
                                {/** HISTORIC START */}
                                <div className="relative m-8">
                                    <div className="my-2">
                                        <p className="text-xs text-gray-700">
                                            Identificador:{' '}
                                            <span className="text-xs text-gray-500">
                                                {indication?.id}
                                            </span>
                                        </p>
                                    </div>

                                    <div className="my-2">
                                        <Bedge title={indication?.status} />
                                    </div>

                                    <hr className="my-2" />

                                    <div className="flex mt-2">
                                        <span className="text-xs text-gray-700">
                                            Parceiro:
                                        </span>
                                        <p className="text-gray-500 text-xs ml-1">
                                            {indication?.partnerName}
                                        </p>
                                    </div>
                                    <div className="flex flex-row items-center justify-between">
                                        <div className="block">
                                            <span className="text-xs text-gray-700">
                                                Criado em:
                                            </span>
                                            <p className="text-gray-500 text-xs">
                                                {indication
                                                    ? new Date(
                                                          indication?.createdAt
                                                              .seconds * 1000
                                                      ).toLocaleString('pt-BR')
                                                    : null}
                                            </p>
                                        </div>
                                        <div className="block">
                                            <span className="text-xs text-gray-700">
                                                Atualizado em:
                                            </span>
                                            <p className="text-gray-500 text-xs">
                                                {indication
                                                    ? new Date(
                                                          indication?.updatedAt
                                                              .seconds * 1000
                                                      ).toLocaleString('pt-BR')
                                                    : null}
                                            </p>
                                        </div>
                                    </div>

                                    <hr className="my-2" />

                                    <div className="inline-flex items-center pl-2 ">
                                        <span className="text-xs text-gray-700 mr-2">
                                            Tipo:
                                        </span>
                                        <p className="px-2 bg-primary text-white rounded">
                                            {indication?.type}
                                        </p>
                                    </div>

                                    <div className="block pl-2 ">
                                        <span className="text-xs text-gray-700">
                                            Nome:
                                        </span>
                                        <p className="text-gray-500 text-sm">
                                            {indication?.name}
                                        </p>
                                    </div>
                                    <div className="block mt-2 pl-2 ">
                                        <span className="text-xs text-gray-700">
                                            CPF:
                                        </span>
                                        <p className="text-gray-500 text-sm">
                                            {indication?.cpf}
                                        </p>
                                    </div>
                                    <div className="block mt-2 pl-2 ">
                                        <span className="text-xs text-gray-700">
                                            Telefone:
                                        </span>
                                        <p className="text-gray-500 text-sm">
                                            {indication?.phone}
                                        </p>
                                    </div>
                                    <div className="pl-2 block mt-2">
                                        <span className="text-xs text-gray-700">
                                            Comissão:
                                        </span>
                                        <p>
                                            <UINumber
                                                format="$ 0,0.00"
                                                customStyle="text-sm text-gray-500"
                                            >
                                                {indication?.bonusValue}
                                            </UINumber>
                                        </p>
                                    </div>
                                    <div className="block mt-2 bg-red-100 rounded px-2 py-1">
                                        <span className="text-xs text-gray-700">
                                            Observação da indicação:
                                        </span>
                                        <p className="text-xs text-gray-500">
                                            {indication?.obs}
                                        </p>
                                    </div>

                                    <hr className="my-2" />

                                    {indication?.messages && (
                                        <div className="block mt-2">
                                            <span className="text-xs text-gray-700">
                                                Histórico de conversa:
                                            </span>
                                            <ul className="space-y-2">
                                                {indication?.messages.map(
                                                    (message, index) => (
                                                        <li
                                                            className={`rounded ${
                                                                message.type ===
                                                                'agent'
                                                                    ? 'bg-orange-200'
                                                                    : 'bg-blue-200'
                                                            }`}
                                                            key={String(index)}
                                                        >
                                                            <p className="text-xs font-light text-gray-500 px-2 py-1">
                                                                <strong className="font-medium">
                                                                    {message.agentName
                                                                        ? message.agentName
                                                                        : 'Atendente'}{' '}
                                                                </strong>
                                                                -{' '}
                                                                {new Date(
                                                                    message?.id
                                                                        .seconds *
                                                                        1000
                                                                ).toLocaleString(
                                                                    'pt-BR'
                                                                )}
                                                            </p>
                                                            <p className="bg-gray-100 font-normal text-xs text-gray-500 px-2">
                                                                {
                                                                    message.message
                                                                }
                                                            </p>
                                                        </li>
                                                    )
                                                )}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IndicationViewModal;
