import React from 'react';

import { NavLink, useRouteMatch } from 'react-router-dom';

const CustomNavLink = ({ to: path, children }) => {
    const match = useRouteMatch();
    const isActive = match.url === path;
    return (
        <NavLink
            to={path}
            className="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800"
            activeClassName="active-link"
            exact
        >
            {isActive && (
                <span
                    className="absolute inset-y-0 left-0 w-1 bg-primary rounded-tr-lg rounded-br-lg"
                    aria-hidden="true"
                />
            )}
            {children}
        </NavLink>
    );
};

export default CustomNavLink;
