import React from 'react';

const Footer = () => {
    return (
        <footer className="absolute bottom-0 block bg-transparent py-2 w-full">
            <div className="flex flex-wrap items-center justify-center">
                <div className="text-xs text-gray-400 font-medium py-1">
                    Copyright © {new Date().getFullYear()} Granacred Indica
                </div>
            </div>
        </footer>
    );
};

export default Footer;
