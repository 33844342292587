import React from 'react';

import Footer from '@/components/Footer';

export default function TermsOfUse() {
    return (
        <div className="relative container mx-auto px-10 py-12">
            <header className="mb-10">
                <h1 className="text-gray-700 font-semibold text-3xl">
                    Política de privacidade
                </h1>
            </header>
            <body className="mb-10">
                <h3 className="text-lg text-gray-600 font-medium leading-10 my-4">
                    SEÇÃO 1 - O QUE FAREMOS COM ESTA INFORMAÇÃO?
                </h3>

                <p className="text-sm text-gray-500 font-normal my-4">
                    Quando você realiza alguma transação com nossa loja, como
                    parte do processo de compra e venda, coletamos as
                    informações pessoais que você nos dá tais como: nome, e-mail
                    e endereço.
                </p>
                <p className="text-sm text-gray-500 font-normal my-4">
                    Quando você acessa nosso site, também recebemos
                    automaticamente o protocolo de internet do seu computador,
                    endereço de IP, a fim de obter informações que nos ajudam a
                    aprender sobre seu navegador e sistema operacional.
                </p>
                <p className="text-sm text-gray-500 font-normal my-4">
                    Email Marketing será realizado apenas caso você permita.
                    Nestes emails você poderá receber notícia sobre nossa loja,
                    novos produtos e outras atualizações.
                </p>

                <h3 className="text-lg text-gray-600 font-medium leading-10 my-4">
                    SEÇÃO 2 - CONSENTIMENTO
                </h3>

                <p className="text-sm text-gray-500 font-normal my-4">
                    Como vocês obtêm meu consentimento?
                </p>

                <p className="text-sm text-gray-500 font-normal my-4">
                    Quando você fornece informações pessoais como nome, telefone
                    e endereço, para completar: uma transação, verificar seu
                    cartão de crédito, fazer um pedido, providenciar uma entrega
                    ou retornar uma compra. Após a realização de ações
                    entendemos que você está de acordo com a coleta de dados
                    para serem utilizados pela nossa empresa.
                </p>

                <p className="text-sm text-gray-500 font-normal my-4">
                    Se pedimos por suas informações pessoais por uma razão
                    secundária, como marketing, vamos lhe pedir diretamente por
                    seu consentimento, ou lhe fornecer a oportunidade de dizer
                    não.
                </p>

                <p className="text-sm text-gray-500 font-normal my-4">
                    E caso você queira retirar seu consentimento, como proceder?
                </p>

                <p className="text-sm text-gray-500 font-normal my-4">
                    Se após você nos fornecer seus dados, você mudar de ideia,
                    você pode retirar o seu consentimento para que possamos
                    entrar em contato, para a coleção de dados contínua, uso ou
                    divulgação de suas informações, a qualquer momento, entrando
                    em contato conosco em{' '}
                    <strong className="text-gray-600">
                        granaindica@grannacred.com.br
                    </strong>{' '}
                    ou nos enviando uma correspondência em:{' '}
                    <strong className="text-gray-600">
                        Granacred - Av Dom Pedro ll 316 - Centro - Presidente
                        Venceslau
                    </strong>
                </p>

                <h3 className="text-lg text-gray-600 font-medium leading-10 my-4">
                    SEÇÃO 3 - DIVULGAÇÃO
                </h3>

                <p className="text-sm text-gray-500 font-normal my-4">
                    Podemos divulgar suas informações pessoais caso sejamos
                    obrigados pela lei para fazê-lo ou se você violar nossos
                    Termos de Serviço.
                </p>

                <h3 className="text-lg text-gray-600 font-medium leading-10 my-4">
                    SEÇÃO 4 - SERVIÇOS DE TERCEIROS
                </h3>

                <p className="text-sm text-gray-500 font-normal my-4">
                    No geral, os fornecedores terceirizados usados por nós irão
                    apenas coletar, usar e divulgar suas informações na medida
                    do necessário para permitir que eles realizem os serviços
                    que eles nos fornecem.
                </p>

                <p className="text-sm text-gray-500 font-normal my-4">
                    Entretanto, certos fornecedores de serviços terceirizados,
                    tais como gateways de pagamento e outros processadores de
                    transação de pagamento, têm suas próprias políticas de
                    privacidade com respeito à informação que somos obrigados a
                    fornecer para eles de suas transações relacionadas com
                    compras.
                </p>

                <p className="text-sm text-gray-500 font-normal my-4">
                    Para esses fornecedores, recomendamos que você leia suas
                    políticas de privacidade para que você possa entender a
                    maneira na qual suas informações pessoais serão usadas por
                    esses fornecedores.
                </p>

                <p className="text-sm text-gray-500 font-normal my-4">
                    Em particular, lembre-se que certos fornecedores podem ser
                    localizados em ou possuir instalações que são localizadas em
                    jurisdições diferentes que você ou nós. Assim, se você quer
                    continuar com uma transação que envolve os serviços de um
                    fornecedor de serviço terceirizado, então suas informações
                    podem tornar-se sujeitas às leis da(s) jurisdição(ões) nas
                    quais o fornecedor de serviço ou suas instalações estão
                    localizados.
                </p>

                <p className="text-sm text-gray-500 font-normal my-4">
                    Como um exemplo, se você está localizado no Canadá e sua
                    transação é processada por um gateway de pagamento
                    localizado nos Estados Unidos, então suas informações
                    pessoais usadas para completar aquela transação podem estar
                    sujeitas a divulgação sob a legislação dos Estados Unidos,
                    incluindo o Ato Patriota.
                </p>

                <p className="text-sm text-gray-500 font-normal my-4">
                    Uma vez que você deixe o site da nossa loja ou seja
                    redirecionado para um aplicativo ou site de terceiros, você
                    não será mais regido por essa Política de Privacidade ou
                    pelos Termos de Serviço do nosso site.
                </p>

                <p className="text-sm text-gray-500 font-normal my-4">
                    Links Quando você clica em links na nossa loja, eles podem
                    lhe direcionar para fora do nosso site. Não somos
                    responsáveis pelas práticas de privacidade de outros sites e
                    lhe incentivamos a ler as declarações de privacidade deles.
                </p>

                <h3 className="text-lg text-gray-600 font-medium leading-10 my-4">
                    SEÇÃO 5 - SEGURANÇA
                </h3>

                <p className="text-sm text-gray-500 font-normal my-4">
                    Para proteger suas informações pessoais, tomamos precauções
                    razoáveis e seguimos as melhores práticas da indústria para
                    nos certificar que elas não serão perdidas inadequadamente,
                    usurpadas, acessadas, divulgadas, alteradas ou destruídas.
                </p>
                <p className="text-sm text-gray-500 font-normal my-4">
                    Se você nos fornecer as suas informações de cartão de
                    crédito, essa informação é criptografada usando tecnologia
                    "secure socket layer" (SSL) e armazenada com uma
                    criptografia AES-256. Embora nenhum método de transmissão
                    pela Internet ou armazenamento eletrônico é 100% seguro, nós
                    seguimos todos os requisitos da PCI-DSS e implementamos
                    padrões adicionais geralmente aceitos pela indústria.
                </p>

                <h3 className="text-lg text-gray-600 font-medium leading-10 my-4">
                    SEÇÃO 6 - ALTERAÇÕES PARA ESSA POLÍTICA DE PRIVACIDADE
                </h3>

                <p className="text-sm text-gray-500 font-normal my-4">
                    Reservamos o direito de modificar essa política de
                    privacidade a qualquer momento, então por favor, revise-a
                    com frequência. Alterações e esclarecimentos vão surtir
                    efeito imediatamente após sua publicação no site. Se
                    fizermos alterações de materiais para essa política, iremos
                    notificá-lo aqui que eles foram atualizados, para que você
                    tenha ciência sobre quais informações coletamos, como as
                    usamos, e sob que circunstâncias, se alguma, usamos e/ou
                    divulgamos elas.
                </p>

                <p className="text-sm text-gray-500 font-normal my-4">
                    Se nossa loja for adquirida ou fundida com outra empresa,
                    suas informações podem ser transferidas para os novos
                    proprietários para que possamos continuar a vender produtos
                    para você.
                </p>
            </body>
            <Footer />
        </div>
    );
}
