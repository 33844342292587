/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { Route, Redirect } from 'react-router-dom';

import { useAuth } from '@/contexts/AuthContext';
import AuthLayout from '@/pages/_layouts/auth';
import DefaultLayout from '@/pages/_layouts/default';

export default function RouteWrapper({
    component: Component,
    isPrivate,
    roles = [],
    ...rest
}) {
    const { signed, user } = useAuth();

    const hasPermission = roles.includes(user?.role);

    if (!signed && isPrivate) {
        return <Redirect to="/" />;
    }

    if (signed && !isPrivate) {
        return <Redirect to="/dashboard" />;
    }

    if (signed && !hasPermission) {
        return <Redirect to="/dashboard" />;
    }

    const Layout = signed ? DefaultLayout : AuthLayout;

    return (
        <Route
            {...rest}
            render={(props) => (
                <Layout>
                    <Component {...props} />
                </Layout>
            )}
        />
    );
}
