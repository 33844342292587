import React from 'react';

import { Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { AuthProvider } from '@/contexts/AuthContext';
import { PartnerProvider } from '@/contexts/PartnerContext';
import { SearchProvider } from '@/contexts/SearchContext';
import { SidebarDrawerProvider } from '@/contexts/SideBarContext';
import Routes from '@/routes';
import history from '@/services/history';

import 'react-toastify/dist/ReactToastify.css';

function App() {
    return (
        <AuthProvider>
            <PartnerProvider>
                <SearchProvider>
                    <Router history={history}>
                        <SidebarDrawerProvider>
                            <Routes />
                            <ToastContainer autoClose={3000} />
                        </SidebarDrawerProvider>
                    </Router>
                </SearchProvider>
            </PartnerProvider>
        </AuthProvider>
    );
}

export default App;
