import React from 'react';

import { Link } from 'react-router-dom';

import { ReactComponent as HomeSvg } from '@/assets/svgs/home.svg';
import { ReactComponent as ListSvg } from '@/assets/svgs/list.svg';
import { ReactComponent as PartnersSvg } from '@/assets/svgs/partners.svg';
import { ReactComponent as PaySvg } from '@/assets/svgs/pay2.svg';
import NavLink from '@/components/NavLink';

const NavBarMobile = ({ propRef }) => {
    return (
        <aside
            className="fixed inset-y-0 bg-white z-20 flex-shrink-0 w-64 mt-16 overflow-y-auto  border-r-2 border-gray-50 md:hidden"
            ref={propRef}
        >
            <div className="py-4 text-gray-500">
                <Link
                    className="ml-6 text-lg font-bold text-primary tracking-wide"
                    to="/dashboard"
                >
                    Grana<strong className="text-gray-500">Indica</strong>
                </Link>
                <ul className="mt-6">
                    <li className="relative px-6 py-3">
                        <NavLink to="/dashboard">
                            <HomeSvg />
                            <span className="ml-4">Dashboard</span>
                        </NavLink>
                    </li>
                    <li className="relative px-6 py-3">
                        <NavLink to="/partners">
                            <PartnersSvg />
                            <span className="ml-4">Parceiros</span>
                        </NavLink>
                    </li>
                    <hr className="my-2 w-full bg-gray-300" />
                    <p className="ml-6 mt-4 text-base text-gray-600 font-semibold">
                        Indicações
                    </p>
                    <li className="relative px-6 py-3">
                        <NavLink to="/indications">
                            <ListSvg />
                            <span className="ml-4">Todas</span>
                        </NavLink>
                    </li>
                    <li className="relative px-6 py-3">
                        <NavLink to="/indications-unpaid">
                            <PaySvg />
                            <span className="ml-4">Pagar</span>
                        </NavLink>
                    </li>
                </ul>
            </div>
        </aside>
    );
};

export default NavBarMobile;
