/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

const Input = ({
    placeholder,
    name,
    value,
    label,
    error,
    helperText,
    onBlur,
    onChange,
    type,
    ...rest
}) => (
    <div className="relative w-full mb-1">
        <label
            htmlFor={name}
            className="block uppercase text-gray-600 text-xs font-bold mb-1"
        >
            {label}
        </label>
        <input
            {...rest}
            className={`
            w-full
            px-3
            py-3
            placeholder-gray-400
            text-gray-700
            rounded
            text-sm
            shadow
            border-1
            focus:outline-none
            focus:ring-1
            focus:ring-primaryHover
            focus:border-primaryHover
            leading-tight
            ${error ? 'border-red-400' : 'border-gray-400'}
            `}
            name={name}
            type={type || 'text'}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            placeholder={placeholder || ''}
            style={{
                transition: 'all 0.15s ease 0s',
            }}
        />
        <p
            className="text-red-500 text-xs mt-1"
            style={{ visibility: `${error ? 'visible' : 'hidden'}` }}
        >
            {helperText || `xxx`}
        </p>
    </div>
);

export default Input;
