import { useContext, createContext, useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

const SidebarDrawerContext = createContext({});

export function SidebarDrawerProvider({ children }) {
    const location = useLocation();
    const [isOpenNavBar, setIsOpenNavBar] = useState(false);

    function handleToggleSidebar() {
        setIsOpenNavBar((prev) => !prev);
    }

    function handleCloseSidebarDrawer() {
        setIsOpenNavBar(false);
    }

    useEffect(() => {
        handleCloseSidebarDrawer();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    return (
        <SidebarDrawerContext.Provider
            value={{
                isOpenNavBar,
                handleToggleSidebar,
                handleCloseSidebarDrawer,
            }}
        >
            {children}
        </SidebarDrawerContext.Provider>
    );
}

export const useSidebarDrawer = () => useContext(SidebarDrawerContext);
