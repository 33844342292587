/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

const ButtonLoading = ({
    variant = 'default',
    loading,
    disabled = false,
    type = 'button',
    customStyle = '',
    children,
    ...rest
}) => {
    const handleButtonStyle = () => {
        switch (variant) {
            case 'success':
                return 'bg-lime-500 ring-lime-400';
            case 'danger':
                return 'bg-red-500 ring-red-400';
            case 'alert':
                return 'bg-orange-500 ring-orange-400';
            case 'secondary':
                return 'bg-rose-500 ring-rose-400';
            default:
                return 'bg-orange-500 ring-orange-400';
        }
    };

    return (
        <button
            // eslint-disable-next-line react/button-has-type
            type={type}
            className={`
            items-center
            justify-center
            inline-flex
            w-full
            text-sm px-4 py-2
            text-white font-semibold
            rounded
            focus:outline-none
            focus:ring-2
            hover:opacity-80
            transition-all
            duration-150
            ${disabled && 'cursor-not-allowed opacity-75'}
            ${handleButtonStyle(variant)}
            ${customStyle}
            `}
            disabled={disabled}
            {...rest}
        >
            {loading && (
                <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                    />
                    <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    />
                </svg>
            )}
            {children}
        </button>
    );
};

export default ButtonLoading;
