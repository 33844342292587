import React from 'react';

import { createPopper } from '@popperjs/core';

const TooltipButton = ({ children, tip, ...rest }) => {
    const [tooltipShow, setTooltipShow] = React.useState(false);
    const btnRef = React.createRef();
    const tooltipRef = React.createRef();
    const openLeftTooltip = () => {
        createPopper(btnRef.current, tooltipRef.current, {
            placement: 'top',
        });
        setTooltipShow(true);
    };
    const closeLeftTooltip = () => {
        setTooltipShow(false);
    };
    return (
        <>
            <div className="flex flex-wrap">
                <div className="w-full text-center">
                    <button
                        className="flex items-center justify-between px-2 py-2 text-sm font-medium leading-5 text-orange-400 rounded-lg focus:outline-none focus:ring-2 ring-orange-400"
                        type="button"
                        onMouseEnter={openLeftTooltip}
                        onMouseLeave={closeLeftTooltip}
                        ref={btnRef}
                        {...rest}
                    >
                        {children}
                    </button>
                    <div
                        className={`${
                            tooltipShow ? '' : 'hidden '
                        }bg-primary border-0 mb-3 block z-50 font-normal leading-normal text-sm max-w-xs text-left no-underline break-words rounded-lg`}
                        ref={tooltipRef}
                    >
                        <div>
                            <div className="text-white p-2">{tip}</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TooltipButton;
